import { Card, Switch, Tooltip } from "antd";
import "./settings-card-item.scss";
import { ProjecSettingApi } from "@/shared/api/project/types";
import { formatDate } from "@/shared/lib/date-fns";
import { UserInfoModal } from "@/shared/ui/user-info-modal";

interface ISettingsCardItem {
  settingsInfo: ProjecSettingApi.IntegrationSetting;
}

export const SettingsCardItem = ({ settingsInfo }: ISettingsCardItem) => {
  return (
    <Card className="project-setting-card" bordered={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <h3 className="project-setting-card-title">
          {settingsInfo.integrationName}
        </h3>
        <Tooltip title="Для изменения перейдите в режим редактирования">
          <Switch
            checked={settingsInfo.isCurrent}
            defaultChecked={true}
            disabled
            className="project-setting-card-switch"
            style={{ marginTop: "0px" }}
          />
        </Tooltip>
      </div>

      {/* {description && (
        <p className="project-setting-card-description">{description}</p>
      )} */}
      <div className="project-setting-card-footer">
        <UserInfoModal>
          <span
            className="project-setting-card-author"
            style={{ cursor: "pointer" }}
          >
            {settingsInfo.updatedBy.userFirstName +
              " " +
              settingsInfo.updatedBy.userSurname}
          </span>
        </UserInfoModal>

        {/* <span className="project-setting-card-date">{formatDate(settingsInfo.updateDate)}</span> */}
      </div>
    </Card>
  );
};
