import { useMemo, useState } from "react";
import {
  Card,
  Input,
  Pagination,
  Table,
  type TableProps,
  Typography,
} from "antd";
import {
  type ProjectParticipantInfo,
  useGetProjectParticipants,
} from "@entities/project";
import { SearchIcon } from "@shared/assets/svg/icons";
import { Can } from "@shared/lib/casl";
import { useCoordinator } from "@shared/lib/hooks";
import { DeleteOrganizationButton } from "../delete-organization-button/delete-organization-button.tsx";
import { MemberLink } from "./member-link.tsx";
import "./project-members-pagination.scss";
import { fallbackString } from "@shared/utils";

const { Title } = Typography;

const columns: TableProps<ProjectParticipantInfo>["columns"] = [
  {
    title: "Организация",
    dataIndex: ["company"],
    render: (value) => {
      const company = value as ProjectParticipantInfo["company"];
      return (
        <div className="project-members-jury-info">
          <MemberLink company={company} />
          <span>ИНН: {company.inn}</span>
          <span>КПП: {company.kpp}</span>
        </div>
      );
    },
    width: "25%",
  },
  {
    title: "Роль",
    // TODO: ждём Роль на бекенде
    // dataIndex: ["company", "inn"],
    key: "inn",
    width: "41.66%",
    render: (value) => fallbackString(value.role),
  },
  {
    title: "Объект",
    // TODO: ждём Объекты на бекенде
    // dataIndex: ["company", "phone"],
    key: "phone",
    width: "25%",
    render: (value) => fallbackString(value.objects),
  },
  {
    title: undefined,
    key: "button",
    render: (_value, row) => (
      <div className="project-members-delete-button">
        <Can I="edit" a="project">
          <DeleteOrganizationButton
            projectParticipantId={row.projectParticipantId}
          />
        </Can>
      </div>
    ),
  },
];

export function ProjectMembersTable() {
  const { projectId } = useCoordinator() as { projectId: string };
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { data, isLoading } = useGetProjectParticipants({
    projectId,
    page,
    search,
    limit: 10,
  });

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const MemoizedTable = useMemo(
    () => (
      <Table
        className="project-members-table"
        dataSource={data?.participants}
        columns={columns}
        rowKey="projectParticipantId"
        pagination={false}
        loading={isLoading}
      />
    ),
    [data?.participants, isLoading],
  );

  const totalPageCount = (data?.total ?? 1) / (data?.limit ?? 1);

  return (
    <>
      <div className="project-members">
        <Card style={{ borderRadius: "16px", backgroundColor: "white" }}>
          <div className="project-members-header">
            <Title className="project-members-title" level={4}>
              Участвующие в проекте организации
            </Title>
            <Input
              className="project-members-search"
              suffix={<SearchIcon />}
              placeholder="Поиск"
              onInput={onSearch}
              value={search}
            />
          </div>
          {MemoizedTable}
        </Card>
        {totalPageCount > 1 ? (
          <Pagination
            className="project-members-pagination"
            total={data?.total}
            showSizeChanger={false}
            pageSize={10}
            onChange={onPageChange}
            current={page}
          />
        ) : null}
      </div>
    </>
  );
}
