import { Typography } from "antd";
import "./journals-notes.scss";
import Table, { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

interface DataType {
  key: number;
  number: number;
  text: string;
  date?: string;
  author: string;
  deadline?: string;
  documents?: string[];
  value?: string | number;
  isDisabled?: boolean;
}

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
    width: 50,
    render: (_, { number, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        <div>{number}</div>
      </div>
    ),
  },
  {
    title: "Текст замечания",
    dataIndex: "text",
    key: "text",
    render: (_, { text, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        <div>{text}</div>к<Link to={"#"}> Версии 2</Link>
      </div>
    ),
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: 120,
    render: (_, { date, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        {date || "-"}
      </div>
    ),
  },
  {
    title: "Автор замечания",
    dataIndex: "author",
    key: "author",
    width: 200,
    render: (_, { author, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        {author || "-"}
      </div>
    ),
  },
  {
    title: "Срок устранения",
    dataIndex: "deadline",
    key: "deadline",
    width: 150,
    render: (_, { deadline, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        {deadline || "-"}
      </div>
    ),
  },
  {
    title: "Приложенные документы",
    dataIndex: "documents",
    key: "documents",
    render: (_, { documents, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        {documents
          ? documents.map((doc) => (
              <div key={doc}>
                <Link to="#">{doc}</Link>
              </div>
            ))
          : "-"}
      </div>
    ),
  },
  {
    title: "Комментируемое значение",
    dataIndex: "value",
    key: "value",
    width: 120,
    render: (_, { value, isDisabled }) => (
      <div className={`${isDisabled ? "disabled-line" : ""}`}>
        {value || "-"}
      </div>
    ),
  },
];

const data: DataType[] = [
  {
    key: 5,
    number: 5,
    text: "Не повышать налоги, а печатать деньги",
    author: "Гусейнов Марат Замечательный писатель ООО “ГосСтройНадзор”",
    documents: ["Документ 1", "Документ 1.sig", "Документ 2", "Ещё 29"],
    value: "14%",
  },
  {
    key: 4,
    number: 4,
    text: "Израсходовать заначку к сентябрю-октябрю",
    date: "25.07.2024",
    author: "Гусейнов Марат Замечательный писатель ООО “ГосСтройНадзор”",
    deadline: "12.08.2024",
    documents: ["Документ"],
    value: 100000000,
  },
  {
    key: 3,
    number: 3,
    text: "Подозрительное знакомое имя уполномоченного представителя со стороны техзака",
    date: "25.07.2024",
    author: "Гусейнов Марат Руководитель отдела замечаний ООО “ГосСтройНадзор”",
    deadline: "12.08.2024",
    value: "Финансистов Пролоббир",
  },
  {
    key: 2,
    number: 2,
    text: "ИИ -- тупиковая ветвь развития",
    date: "12.07.2024",
    author: "Бегларян Григорий Инженер по отказам ООО “ГосСтройНадзор”",
    deadline: "10.08.2024",
    isDisabled: true,
  },
  {
    key: 1,
    number: 1,
    text: "Не повышать налоги, а печатать деньги",
    date: "12.07.2024",
    author: "Гусейнов Марат РемаркРайтер ООО “ГосСтройНадзор”",
    deadline: "10.08.2024",
    value: "14%",
    isDisabled: true,
  },
];

export const JournalsNotes = () => {
  return (
    <div className="table-container journals-notes-table">
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        title={() => (
          <Typography.Title level={4}>Список замечаний из ГСН</Typography.Title>
        )}
      />
    </div>
  );
};
