import { ReactNode, useState } from "react";
import { Modal, notification } from "antd";
import { User } from "@entities/user/types/user";
import { useDisclosure } from "@shared/lib/hooks";
import { RepresentationFormEmail } from "../representation-form-email";
import { CloseOutlined } from "@ant-design/icons";

export function SelectAuthorizedRepresentationWrapper({
  children,
  setRepresentative,
}: {
  children: ({ onOpen }: { onOpen: () => void }) => ReactNode;
  setRepresentative: (user: User) => void;
}) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [api, contextHolder] = notification.useNotification();
  // TODO: тут форму
  const [foundedUser, setFoundedUser] = useState<undefined | User>(undefined);
  const sendNudes = () => {
    if (foundedUser) {
      setRepresentative(foundedUser);
      setFoundedUser(undefined);
      onClose();
    }
    if (!foundedUser) {
      api.warning({
        message: `Не выбран представитель`,
        description: 'Введите корректные данные и нажмите кнопку "найти"',
        placement: "topRight",
        showProgress: false,
      });
    }
  };
  const onSuccess = (item: User) => {
    setFoundedUser(item)
  }
  return (
    <>
      {children({ onOpen })}
      {contextHolder}
      <Modal
        title="Добавление Уполномоченного"
        open={isOpen}
        onOk={sendNudes}
        onCancel={onClose}
        destroyOnClose
        okText="Выбрать"
        okButtonProps={{ disabled: !foundedUser }}
        centered
        width={700}
        cancelText="Отмена"
      >
        <div style={{ marginBottom: "24px", color: "#333333" }}>
          Поиск сотрудника по адресу электронной почты
        </div>
        <RepresentationFormEmail
          setIsShowFormAdd={() => {}}
          onSuccess={onSuccess}
        />
        {foundedUser?.userId && (
          <p
            style={{
              color: "#333333",
              display: "flex",
              alignItems: "center",
              gap: 16,
            }}
          >
            {foundedUser.userId}{" "}
            <CloseOutlined
              onClick={() => setFoundedUser(undefined)}
              style={{ color: "red", cursor: "pointer" }}
            />
          </p>
        )}
      </Modal>
    </>
  );
}
