import "./navigation-contracts.scss";
import { motion } from "framer-motion";

export const NavigationContracts = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <motion.div
      className="navigation-contracts-block"
      style={{ visibility: collapsed ? "hidden" : "visible" }}
      animate={{
        visibility: collapsed ? "hidden" : "visible",
        opacity: collapsed ? 0 : 1,
      }}
    >
      <p className="navigation-contracts-label">ДОГОВОРЫ ПРОЕКТА</p>
    </motion.div>
  );
};
