import { IConstructionType } from "@entities/object";

export const generateConstructionTypeName = (type: string): string => {
  const translationMap: Record<IConstructionType, string> = {
    [IConstructionType.CONSTRUCTION]: "Строительство",
    [IConstructionType.RECONSTRUCTION]: "Реконструкция",
    [IConstructionType.RENOVATION]: "Реновация",
  };

  if (type in translationMap) {
    return translationMap[type as IConstructionType];
  }

  return "-";
};
