import { JSX, ReactNode, useEffect, useState } from "react";
import { Button, Input, Modal, notification } from "antd";
import { useDisclosure } from "@shared/lib/hooks";
import { CertificateSelector } from "../certificate-selector";
import {
  CryptographyNotification,
  CryptographyNotificationTypeList,
} from "../cryptography-notification";

import "./cryptography-wrapper.scss";
import { useCryptographyStore } from "../../model";
import { ProxySelector } from "../proxy-selector";

interface ChildrenProps {
  onOpen: () => void;
}

type CryptographyWrapperProps = {
  children: (props: ChildrenProps) => ReactNode | JSX.Element;
  signType: "string" | "xml";
};

export const CryptographyWrapper = ({
  children,
  signType = "xml",
}: CryptographyWrapperProps) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    fetchCertificates,
    init,
    isInitialized,
    selectedCertificate,
    signXML,
    verifyXML,
    signString,
    verifyString,
  } = useCryptographyStore();

  const [modalType, setModalType] = useState<"Sign" | "CreateProxy">("Sign");

  const [forSignValue, setForSignValue] = useState<string>("");
  const [signedData, setSignedData] = useState<string>("");

  useEffect(() => {
    // в первую очередь, перед всеми операциями необходимо инициализироваться
    if (isOpen && !isInitialized) init();
  }, [isOpen]);

  useEffect(() => {
    if (isInitialized && isOpen) fetchCertificates();
  }, [isInitialized, isOpen]);

  const createSign = async () => {
    if (!selectedCertificate || !selectedCertificate?.Thumbprint)
      notification.error({
        message: "Произошла ошибка",
        description: "Сертификат не выбран / отстутствует thumbPrint",
        placement: "topRight",
      });
    if (selectedCertificate && "Thumbprint" in selectedCertificate) {
      if (signType === "string") {
        signString(forSignValue, selectedCertificate.Thumbprint);
      }

      if (signType === "xml") {
        signXML(forSignValue, selectedCertificate.Thumbprint);
      }
    }
  };
  const checker = () => {
    if (selectedCertificate) {
      if (signType === "string") {
        verifyString(signedData, selectedCertificate.Thumbprint);
      }

      if (signType === "xml") {
        verifyXML(signedData);
      }
    }
  };

  return (
    <>
      {children({ onOpen })}

      <Modal
        onCancel={onClose}
        onOk={createSign}
        okText="Подписать"
        okButtonProps={{
          disabled: Boolean(!forSignValue) || !selectedCertificate?.IsValid,
        }}
        open={isOpen}
        className="cryptography-wrapper-modal"
        destroyOnClose
        width={720}
        title="Подписание"
        classNames={{ header: "cryptography-modal-header" }}
      >
        <Input
          value={signedData}
          style={{ position: "absolute", bottom: 16, left: 24, width: 200 }}
          placeholder="SIGNED ONLY"
          onChange={(v) => setSignedData(v.target.value)}
        />
        <Button
          style={{ position: "absolute", bottom: 16, left: 150, zIndex: 10 }}
          onClick={checker}
        >
          Проверить {signType}
        </Button>
        <Input
          value={forSignValue}
          onChange={(v) => setForSignValue(v.target.value)}
        />
        {selectedCertificate && !selectedCertificate.IsValid && (
          <CryptographyNotification
            notificationType={
              CryptographyNotificationTypeList.InvalidCertificate
            }
          />
        )}
        <CertificateSelector />
        {/* ВЫБОР ДОВЕРЕННОСТЕЙ НИЖЕ, А ТАКЖЕ НАПИСАН СТЕЙТ modalType */}
        {/* <ProxySelector /> */}
      </Modal>
    </>
  );
};
