import { SettingsCardBlock } from "@/features/settings-card-block/ui/settings-card-block";
import { ProjectSettingsIndividual } from "../project-settings-individual";
import "./project-settings.scss";
import { useCoordinator } from "@/shared/lib/hooks";
import { notification } from "antd";
import { useQuery } from "@tanstack/react-query";
import { projectQueries } from "@/entities/project";

export const ProjectSettings = () => {
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useCoordinator() as { projectId: string };
  const { data, isLoading, isError, error } = useQuery(
    projectQueries.getProjectSettings({ projectId: projectId }),
  );

  if (isLoading) {
    console.log("isLoading");
  }

  if (isError && error) {
    console.log(isError);
    api.error({
      message: "Не можем отобразить настройки проекта",
      description: ``,
      placement: "topRight",
      showProgress: false,
    });
  }

  return (
    <>
      {contextHolder}
      <SettingsCardBlock
        blockTitle={"Интеграции"}
        settingsList={data?.settings}
        isLoading={isLoading}
      />
      {/* <ProjectSettingsIndividual /> */}
    </>
  );
};

