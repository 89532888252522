import { GetTitleJournalResponse } from "@shared/api/journal";
import { journalsServiceApi } from "@shared/api";

export type GetTitleJournalRequest = {
  titleJournalId: string;
};

export const getTitleJournal = ({
  titleJournalId,
}: GetTitleJournalRequest): Promise<GetTitleJournalResponse> => {
  return journalsServiceApi
    .get<GetTitleJournalResponse>(`journals/title/${titleJournalId}`)
    .then((response) => response.data);
};
