import { NotFoundFallback } from "@shared/ui";

export function NotFoundPage() {
  return (
    <NotFoundFallback
      style={{
        height: "100vh",
      }}
    />
  );
}
