import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ProjectObjects } from "./ui";

export const ProjectObjectRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.objects.root()}`,
    element: createElement(ProjectObjects),
    children: [...rest],
  };
};
