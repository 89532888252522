import "./journals-supervision-registration.scss";
import { Col, Row, Tooltip, Typography } from "antd";
import { Fragment } from "react/jsx-runtime";
import SigIcon from "@/shared/assets/svg/icons/sig-icon.png";

const { Title, Text } = Typography;

const tableMockFirst = [
  {
    id: 1,
    title: "Регистрационный номер",
    info: "127",
  },
  {
    id: 2,
    title: "Дата регистрации",
    info: "01.02.2003",
  },
];

const tableMockSecond = [
  {
    id: 3,
    title: "ФИО",
    info: "Надзорный Олег Викторович",
  },
  {
    id: 4,
    title: "Должность",
    info: "Вноситель регистрационных записей",
  },
  {
    id: 5,
    title: "Подпись",
    info: "Подпись.sig",
    isSigned: true,
  },
  {
    id: 6,
    title: "Доверность МЧД",
    info: "123F3210-9X12-9X12-9X12-7DA6A65E654B",
  },
];

export const JournalsSupervisionRegistration = () => {
  return (
    <div className="table-container">
      <div className="flex-between">
        <Title
          style={{
            marginBottom: "24px",
          }}
          level={4}
        >
          Регистрационная надпись органа государственного строительного надзора
        </Title>
      </div>
      <Row gutter={[16, 16]}>
        {tableMockFirst.map(({ id, title, info }) => (
          <Fragment key={id}>
            <Col span={8} style={{ color: "#888888", lineHeight: "20px" }}>
              {title}
            </Col>
            <Col
              span={16}
              className="table-info-content"
              style={{ lineHeight: "20px" }}
            >
              {info}
            </Col>
          </Fragment>
        ))}
      </Row>
      <div
        style={{
          fontSize: "16px",
          marginTop: "24px",
          marginBottom: "24px",
          color: "#333333",
          lineHeight: "24px",
        }}
      >
        Представитель Госстройнадзора, внёсший регистрационную запись
      </div>
      <Row gutter={[16, 16]}>
        {tableMockSecond.map(({ id, title, info, isSigned }) => (
          <Fragment key={id}>
            <Col span={8} style={{ color: "#888888", lineHeight: "20px" }}>
              {title}
            </Col>
            <Col
              span={16}
              className="table-info-content"
              style={{ lineHeight: "20px" }}
            >
              {!isSigned && <>{info}</>}
              {isSigned ? (
                <div className="flex-between">
                  {info}
                  <Tooltip title="Скачать подпись">
                    <img
                      src={SigIcon}
                      alt=""
                      width={16}
                      style={{ height: "16px", cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </Col>
          </Fragment>
        ))}
      </Row>
    </div>
  );
};
