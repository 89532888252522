import { Button } from "antd";
import { CryptographyWrapper } from "@features/cryptography";

export function TestPage() {
  return (
    <div>
      <CryptographyWrapper signType="xml">
        {({ onOpen }) => <Button onClick={onOpen}>открыть</Button>}
      </CryptographyWrapper>
    </div>
  );
}
