import "./withdraw-modal-content.scss";

import { Input, Button } from "antd";

export const WithdrawModalContent = () => {
  return (
    <>
      <Input.TextArea
        placeholder="Опишите причину решения. Ошибка, например"
        rows={4}
      />
      <div style={{ display: "flex", gap: "8px", marginTop: 16 }}>
        <Button type="primary">Отозвать</Button>
        <Button>Отменить</Button>
      </div>
    </>
  );
};

