import { Link, useNavigate } from "react-router-dom";
import DogImg from "@shared/assets/png/dock-project.png";
import { BaseErrorPage } from "@shared/ui/error-fallbacks/base-error-page.tsx";
import { Button, Tooltip } from "antd";
import { routes } from "@/shared/lib/react-router";

interface NotFoundPageProps {
  className?: string;
  style?: React.CSSProperties;
  setIsDev?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NotFoundFallback({
  className,
  style,
  setIsDev,
}: NotFoundPageProps) {
  const navigate = useNavigate();
  const handleOpenDev = () => {
    setIsDev && setIsDev(true);
    navigate(routes.journals.title("123"));
  };

  return (
    <BaseErrorPage
      className={className}
      style={style}
      title="404. Страница не найдена"
      description={
        <span>
          Возвращайтесь на{" "}
          <Link
            to="/"
            style={{
              color: "#0034AD",
            }}
          >
            Главную страницу
          </Link>
          <Tooltip title="Временное решение чтоб можно было верстку поглядеть">
            <Button type="link" onClick={handleOpenDev}>
              Запустить дев
            </Button>
          </Tooltip>
        </span>
      }
      asset={<img width={140} height={216} src={DogImg} alt="BuildDog" />}
    />
  );
}
