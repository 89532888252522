import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";
import "./document-selector.scss";

interface IDocumentSelector {
  loadDocuments: {
    [key: string]: { title: string; status: boolean; id: number };
  };
  toggleDocumentStatus: (key: string) => void;
  style?: React.CSSProperties;
}

export const DocumentSelector = ({
  loadDocuments,
  toggleDocumentStatus,
  style,
}: IDocumentSelector) => {
  return (
    <div className="document-selector-container" style={style}>
      {Object.keys(loadDocuments).map((item, index) => {
        return loadDocuments[item].status ? (
          <div
            className="document-selector-button"
            key={loadDocuments[item].id}
            style={index === 0 ? { marginBottom: "16px" } : {}}
          >
            <Link to="/" className="document-selector-button-title link">
              Название документа.pdf (4kB)
            </Link>
            <CloseOutlined
              onClick={() => toggleDocumentStatus(item)}
              className="close-icon"
            />
          </div>
        ) : (
          <div
            className="document-selector-button"
            style={
              index === 0
                ? { marginBottom: "16px", justifyContent: "space-between" }
                : { justifyContent: "space-between" }
            }
            key={loadDocuments[item].id}
          >
            <div className="document-selector-button-title">
              {loadDocuments[item].title}
            </div>
            <Button onClick={() => toggleDocumentStatus(item)}>
              Загрузить
            </Button>
          </div>
        );
      })}
    </div>
  );
};

