import { Input } from "antd";
import "./input-search.scss";
import { SearchIcon } from "@/shared/assets/svg/icons";

interface IInputSearch {
  placeholder?: string;
  style?: React.CSSProperties;
}

export const InputSearch = ({ placeholder = "Поиск", style }: IInputSearch) => {
  return (
    <Input
      placeholder={placeholder}
      suffix={<SearchIcon />}
      className="input-search-container"
      style={style}
    />
  );
};

