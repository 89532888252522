import { queryOptions } from "@tanstack/react-query";
import {
  getDraftJournals,
  GetDraftJournalsRequest,
} from "./get-draft-journals";
import { getTitleJournal, GetTitleJournalRequest } from "./get-title-journal";

export const journalsQueries = {
  rootKey: () => ["journals"],

  getDraftJournals: ({
    buildingObjectId,
    page,
    limit,
  }: GetDraftJournalsRequest) =>
    queryOptions({
      queryKey: [
        ...journalsQueries.rootKey(),
        "getDraftJournals",
        buildingObjectId,
        page,
        limit,
      ],
      queryFn: () => getDraftJournals({ buildingObjectId, page, limit }),
    }),

  getTitleJournal: ({ titleJournalId }: GetTitleJournalRequest) =>
    queryOptions({
      queryKey: [
        ...journalsQueries.rootKey(),
        "getTitleJournal",
        titleJournalId,
      ],
      queryFn: () => getTitleJournal({ titleJournalId }),
    }),
};

