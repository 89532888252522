import { useQuery } from "@tanstack/react-query";
import { ObjectService, ObjectQueriesTags } from "@shared/api/object";

interface IUseGetObjects {
  page?: number;
  limit?: number;
  projectId?: string;
}

export function useObjects({
  page = 1,
  limit = 1000,
  projectId = "",
}: IUseGetObjects) {
  return useQuery({
    queryFn: () => ObjectService.getAllObjects({ projectId, page, limit }),
    queryKey: ObjectQueriesTags.getAllObjects(),
    enabled: !!projectId,
  });
}
