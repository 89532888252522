import { AxiosResponse } from "axios";
import { userServiceApi } from "@shared/api";
import { User } from "../types";

type FindUserRequest = {
  email: string;
};
type FindUserResponse = User;
export const findUser = (
  data: FindUserRequest,
): Promise<AxiosResponse<FindUserResponse>> => {
  return userServiceApi
    .get("api/users/by-email", {
      params: { email: data.email },
    })
    .then((r) => r.data);
};
