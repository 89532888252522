import { JournalsSubscribersTable } from "@/features/journals-subscribers-table";
import "./journals-section-first.scss";
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { InputSelect } from "@/shared/ui/input-select";
import { InputSearch } from "@/shared/ui/input-search";
import {
  StatusDeclinedIcon,
  StatusPendingIcon,
  StatusSignedIcon,
} from "@/shared/assets/svg/icons";
import { IStatus } from "@/shared/ui/input-select/input-select";
import { JournalSectionForm } from "@/features/journal-section-form/ui";
import { InputDate } from "@/shared/ui/input-date";
import dayjs from "dayjs";
import { ModalInfo } from "@/features/modal-info";
import { JournalsResponsibleTable } from "@/features/journals-responsible-table";

enum RecordStatus {
  Signed = "signed",
  Pending = "pending",
  Declined = "declined",
}

const statusIcons = {
  [RecordStatus.Signed]: <StatusSignedIcon />,
  [RecordStatus.Pending]: <StatusPendingIcon />,
  [RecordStatus.Declined]: <StatusDeclinedIcon />,
};

const dataSource = [
  {
    key: "1",
    number: 1,
    fullName: "ООО 'Строительные технологии в бизнесе'",
    person: "Иванов А. Б.",
    role: "Директор",
    startDate: "15.10.2024",
    workType: "Строительство фундамента",
    endDate: "16.11.2024",
    representative: "Иванов О.П.",
    representativeRole: "Представитель застройщика",
    status: RecordStatus.Signed,
    tooltip: "Подписано 12.11.2024 12:23 МСК",
  },
  {
    key: "2",
    number: 2,
    fullName: "ООО 'Строительные технологии в бизнесе'",
    person: "Иванов А. Б.",
    role: "Директор",
    startDate: "15.11.2024",
    workType: "Организация",
    endDate: "16.11.2024",
    representative: "Иванов О.П.",
    representativeRole: "Представитель застройщика",
    status: RecordStatus.Pending,
    tooltip: "Подписано 12.11.2024 12:23 МСК",
  },
  {
    key: "3",
    number: 3,
    fullName: "ООО 'Строительные технологии в бизнесе'",
    person: "Иванов А. Б.",
    role: "Директор",
    startDate: "15.10.2024",
    workType: "Организация вечеринок и праздников для строителей",
    endDate: "16.11.2024",
    representative: "Иванов О.П.",
    representativeRole: "Представитель застройщика",
    status: "approved",
    tooltip: "Подписано 12.11.2024 12:23 МСК",
  },
  {
    key: "4",
    number: 4,
    fullName: "ООО 'Субподрядчик'",
    person: "Иванов А. Б.",
    role: "Начальник ПТО",
    startDate: "15.10.2023",
    workType: "входной контроль",
    endDate: "27.12.2023",
    representative: "Иванов О.П.",
    representativeRole: "Представитель застройщика",
    status: RecordStatus.Declined,
    tooltip: "Подписано 12.11.2024 12:23 МСК",
  },
  {
    key: "5",
    number: 5,
    fullName: "ООО 'Генподрядчик'",
    person: "Иванов И. И.",
    role: "Инженер-геодезист",
    startDate: "15.10.2023",
    workType: "геодезические работы",
    endDate: "27.12.2023",
    representative: "Застроев Г. А.",
    representativeRole: "Начальник",
    status: RecordStatus.Declined,
    tooltip: "Подписано 12.11.2024 12:23 МСК",
  },
];

export const JournalsSectionFirst = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [creatFormTitle, setCreatFormTitle] = useState(
    "Добавление записи в раздел 1 ОЖР",
  );
  const [isForm, setIsForm] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalInfo, setModalInfo] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [cancelText, setCancelText] = useState("");

  const [inputPLaceholder, setInputPLaceholder] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isInputModal, setIsInputModal] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChangeValue = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleResponceSigning = () => {
    setModalTitle("Отозвать подписание");
    setModalInfo(
      "Вы действительно хотите отозвать отправленную на подписание запись?",
    );
    setInputPLaceholder("Причина отзыва");
    setConfirmText("Отозвать");
    setCancelText("Отменить");
    setIsModalVisible(true);
    setIsInputModal(true);
  };

  const handleOpenSigning = () => {
    setModalTitle("Отправка на подписание");
    setModalInfo("Найдено 45 записей готовых к отправке на подписание");
    setIsModalVisible(true);
    setConfirmText("Отправить");
    setCancelText("Отменить");
  };

  const handleStopRight = () => {
    setModalTitle(
      "Вы действительно хотите приостановить право сотрудника Харин Дмитрий?",
    );
    setModalInfo(
      "Приостановка прав сотрудника может понадобится когда требуется забрать права заполнения, подписания. Причиной могут быть отпуск, смена должности, временное прекращение деятельности. Приостановка права действует отдельно на один раздел ОЖР.  Записи, отправленные на подписание данному сотруднику, нужно будет отредактировать (изменить подписанта).",
    );
    setIsModalVisible(true);
    setConfirmText("Приостановить право");
    setCancelText("Отменить");
  };

  const handleReopenRight = () => {
    setModalTitle("Вы действительно хотите возобновить права сотрудника?");
    setModalInfo("");
    setIsModalVisible(true);
    setConfirmText("Возобновить");
    setCancelText("Отменить");
  };

  const handleConfirm = () => {
    setModalTitle("Вы действительно хотите возобновить права сотрудника?");
    setModalInfo("");
    setIsModalVisible(false);
    setConfirmText("");
    setCancelText("");
    setIsInputModal(false);
  };

  const handleCancel = () => {
    setModalTitle("");
    setModalInfo("");
    setIsModalVisible(false);
    setConfirmText("");
    setCancelText("");
    setIsInputModal(false);
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRowKeys(dataSource.map((item) => item.key));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={selectedRowKeys.length === dataSource.length}
          indeterminate={
            selectedRowKeys.length > 0 &&
            selectedRowKeys.length < dataSource.length
          }
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      dataIndex: "number",
      key: "number",
      align: "center",
      render: (text: any, record: any) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const newSelectedRowKeys = e.target.checked
              ? [...selectedRowKeys, record.key]
              : selectedRowKeys.filter((key) => key !== record.key);
            setSelectedRowKeys(newSelectedRowKeys);
          }}
        >
          {text}
        </Checkbox>
      ),
      sorter: (a: any, b: any) => a.number - b.number,
    },
    {
      title:
        "Полное и (или) сокращенное наименование или фамилия, имя отчество (последнее - при наличии) лица, осуществляющего строительство",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: any) => (
        <span style={{ whiteSpace: "pre-line" }}>{text}</span>
      ),
    },
    {
      title:
        "Фамилия, инициалы, должность (при наличии) лица, входящего в список инженерно-технического персонала",
      dataIndex: "person",
      key: "person",
      render: (text: any, record: any) => (
        <>
          <a href={`#`}>{text}</a>
          <br />
          <span>{record.role}</span>
        </>
      ),
    },
    {
      title:
        "Дата начала работ на объекте капитального строительства с указанием вида работ",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: any, record: any) => (
        <>
          {text}
          <br />
          <span>{record.workType}</span>
        </>
      ),
    },
    {
      title: "Дата окончания работ",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title:
        "Должность (при наличии), фамилия, инициалы, подпись уполномоченного представителя лица, осуществляющего строительство",
      dataIndex: "representative",
      key: "representative",
      render: (
        text: any,
        record: {
          status: RecordStatus;
          tooltip: string;
          representativeRole: string;
        },
      ) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              icon={<UserOutlined />}
              onClick={handleOpenSigning}
            >
              Отправить на подписание
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UserOutlined />}
              onClick={handleResponceSigning}
            >
              Отозвать
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<EditOutlined />}
              onClick={() =>
                handleIsForm(true, undefined, "Редактирование записи")
              }
            >
              Редактировать
            </Menu.Item>
            <Menu.Item key="4" icon={<DeleteOutlined />}>
              Удалить
            </Menu.Item>
            <Menu.Item key="5" onClick={handleStopRight}>
              Приостановить право
            </Menu.Item>
            <Menu.Item key="6" onClick={handleReopenRight}>
              Возобновить право
            </Menu.Item>
          </Menu>
        );

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className="flex-default">
                <a href={`#`} style={{ marginRight: "8px" }}>
                  {text}
                </a>

                <Tooltip title={record.tooltip}>
                  {statusIcons[record.status]}
                </Tooltip>
              </div>

              <span>{record.representativeRole}</span>
            </div>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button
                style={{ border: "none", boxShadow: "none" }}
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const handleIsForm = (
    status: boolean,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    title?: string,
    //  objectId?: string
  ) => {
    event && event.stopPropagation();
    setIsForm(status);
    setCreatFormTitle(title ? title : "Добавление записи в раздел 1 ОЖР");
  };

  return (
    <>
      <div className="section-info">
        Раздел 1. Список инженерно-технического персонала лица, осуществляющего
        строительство, реконструкцию, капитальный ремонт, занятого при
        строительстве, реконструкции, капитальном ремонте объекта капитального
        строительства
      </div>
      {isForm ? (
        <JournalSectionForm
          handleIsForm={handleIsForm}
          title={creatFormTitle}
        />
      ) : null}
      <JournalsResponsibleTable />
      <JournalsSubscribersTable />
      <div className="table-container">
        <Typography.Title level={4} style={{ marginBottom: "16px" }}>
          Раздел 1
        </Typography.Title>
        <div className="flex-between" style={{ marginBottom: "16px" }}>
          <Button type="primary" onClick={(event) => handleIsForm(true, event)}>
            Добавить запись
          </Button>
          <Button type="dashed" onClick={handleOpenSigning}>
            Отправка записей на подписание
          </Button>
        </div>
        <div className="flex-between" style={{ marginBottom: "16px" }}>
          <InputSearch style={{ width: "25%", fontSize: "12px" }} />
          <div className="flex-default">
            <div style={{ marginRight: "8px", fontSize: "12px" }}>
              Фильтрация
            </div>
            <InputSelect
              title="Статус записи"
              style={{ marginRight: "8px" }}
              type="select"
              statuses={[
                {
                  id: "1",
                  status: "Подписано",
                  icon: <StatusSignedIcon />,
                },
                {
                  id: "2",
                  status: "Ожидает подписания",
                  icon: <StatusPendingIcon />,
                },
                {
                  id: "3",
                  status: "Отклонено",
                  icon: <StatusDeclinedIcon />,
                },
                {
                  id: "4",
                  status: "Отправлено в ГСН",
                  icon: <StatusSignedIcon />,
                },
              ]}
              selectedStatuses={[]}
              setSelectedStatuses={() => {}}
            />
            <InputDate
              title="Выберите даты"
              style={{ maxWidth: "500px" }}
              onChange={(dates) => console.log("Выбранные даты:", dates)}
            />
          </div>
        </div>

        <Table
          dataSource={dataSource}
          //@ts-ignore
          columns={columns}
          pagination={false}
          rowKey="key"
        />
      </div>
      <ModalInfo
        visible={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={modalTitle}
        Info={modalInfo}
        confirmText={confirmText}
        cancelText={cancelText}
        inputPlaceholder={inputPLaceholder}
        onChangeValue={onChangeValue}
        inputValue={inputValue}
        isInputModal={isInputModal}
      />
    </>
  );
};

