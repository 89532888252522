/**
 * CryptoPRO simplified library
 * @author Aleksandr.ru
 * @link http://aleksandr.ru
 * extended by @webtensei
 */

import { notification } from "antd";
import {
  X509KeySpec,
  X509PrivateKeyExportFlags,
  X509CertificateEnrollmentContext,
  X509KeyUsageFlags,
  X500NameFlags,
  EncodingType,
  InstallResponseRestrictionFlags,
  ProviderTypes,
  cadesErrorMesages,
} from "./constants";
import DN from "./dn";
import { convertDN, versionCompare } from "./helpers";

function CryptoPro() {
  // If the string contains fewer than 128 bytes, the Length field of the TLV triplet requires only one byte to specify the content length.
  // If the string is more than 127 bytes, bit 7 of the Length field is set to 1 and bits 6 through 0 specify the number of additional bytes used to identify the content length.
  const maxLengthCSPName = 127;

  // https://www.cryptopro.ru/forum2/default.aspx?g=posts&m=38467#post38467
  const asn1UTF8StringTag = 0x0c; // 12, UTF8String

  let canAsync;
  let pluginVersion = "";
  let binded = false;
  let signerOptions = 0;

  /**
   * Инициализация и проверка наличия требуемых возможностей
   * @returns {Promise<Object>} версия
   */
  this.init = function () {
    window.cadesplugin_skip_extension_install = true; // считаем что уже все установлено
    window.allow_firefox_cadesplugin_async = true; // FF 52+

    import("./cadesplugin_api");
    canAsync = !!cadesplugin.CreateObjectAsync;
    // signerOptions = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN;
    signerOptions = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY;

    return new Promise((resolve) => {
      if (!window.cadesplugin) {
        throw new Error("КриптоПро ЭЦП Browser plug-in не обнаружен");
      }
      resolve();
    }).then(() => {
      if (canAsync) {
        return cadesplugin
          .then(() => cadesplugin.CreateObjectAsync("CAdESCOM.About"))
          .then((oAbout) => oAbout.Version)
          .then((version) => {
            pluginVersion = version;
            return { version };
          })
          .catch((e) => {
            // 'Плагин не загружен'
            const err = getError(e);
            throw new Error(err);
          });
      }

      return new Promise((resolve) => {
        try {
          const oAbout = cadesplugin.CreateObject("CAdESCOM.About");
          if (!oAbout || !oAbout.Version) {
            throw new Error("КриптоПро ЭЦП Browser plug-in не загружен");
          }
          pluginVersion = oAbout.Version;
          resolve({
            version: pluginVersion,
          });
        } catch (e) {
          // 'Плагин не загружен'
          const err = getError(e);
          throw new Error(err);
        }
      });
    });
  };

  /**
   * Включает кеширование ПИНов от контейнеров чтоб не тробовать повторного ввода
   * возможно не поддерживается в ИЕ
   * @see https://www.cryptopro.ru/forum2/default.aspx?g=posts&t=10170
   * @param {string} userPin не используется
   * @returns {Promise<boolean>} new binded state
   */
  this.bind = function (userPin) {
    binded = true;
    return Promise.resolve(binded);
  };

  /**
   * Заглушка для совместимости
   * @returns {Promise<boolean>} new binded state
   */
  this.unbind = function () {
    binded = false;
    return Promise.resolve(binded);
  };

  /**
   * Получение информации о сертификате.
   * @param {string} certThumbprint
   * @param {object} [options]
   * @param {boolean} [options.checkValid] проверять валидность сертификата через СКЗИ, а не сроку действия
   * @returns {Promise<Object>}
   */
  this.certificateInfo = function (certThumbprint, options) {
    if (!options)
      options = {
        checkValid: false,
      };
    const infoToString = function () {
      return `Название:              ${this.Name}\nИздатель:              ${
        this.IssuerName
      }\nСубъект:               ${this.SubjectName}\nВерсия:                ${
        this.Version
      }\nАлгоритм:              ${
        this.Algorithm // PublicKey Algorithm
      }\nСерийный №:            ${this.SerialNumber}\nОтпечаток SHA1:        ${
        this.Thumbprint
      }\nНе действителен до:    ${this.ValidFromDate}\nНе действителен после: ${
        this.ValidToDate
      }\nПриватный ключ:        ${
        this.HasPrivateKey ? "Есть" : "Нет"
      }\nКриптопровайдер:       ${
        this.ProviderName // PrivateKey ProviderName
      }\nВалидный:              ${this.IsValid ? "Да" : "Нет"}`;
    };

    if (canAsync) {
      let oInfo = {};
      return getCertificateObject(certThumbprint)
        .then((oCertificate) =>
          Promise.all([
            oCertificate.HasPrivateKey(),
            options.checkValid
              ? oCertificate.IsValid().then((v) => v.Result)
              : undefined,
            oCertificate.IssuerName,
            oCertificate.SerialNumber,
            oCertificate.SubjectName,
            oCertificate.Thumbprint,
            oCertificate.ValidFromDate,
            oCertificate.ValidToDate,
            oCertificate.Version,
            oCertificate
              .PublicKey()
              .then((k) => k.Algorithm)
              .then((a) => a.FriendlyName),
            oCertificate
              .HasPrivateKey()
              .then(
                (key) =>
                  (!key && ["", undefined]) ||
                  oCertificate.PrivateKey.then((k) =>
                    Promise.all([k.ProviderName, k.ProviderType]),
                  ),
              ),
          ]),
        )
        .then((a) => {
          oInfo = {
            HasPrivateKey: a[0],
            IsValid: a[1],
            IssuerName: a[2],
            Issuer: undefined,
            SerialNumber: a[3],
            SubjectName: a[4],
            Subject: undefined,
            Name: undefined,
            Thumbprint: a[5],
            ValidFromDate: new Date(a[6]),
            ValidToDate: new Date(a[7]),
            Version: a[8],
            Algorithm: a[9],
            ProviderName: a[10][0],
            ProviderType: a[10][1],
          };
          oInfo.Subject = string2dn(oInfo.SubjectName);
          oInfo.Issuer = string2dn(oInfo.IssuerName);
          oInfo.Name = oInfo.Subject.CN;
          if (!options.checkValid) {
            const dt = new Date();
            oInfo.IsValid =
              dt >= oInfo.ValidFromDate && dt <= oInfo.ValidToDate;
          }
          oInfo.toString = infoToString;
          return oInfo;
        })
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }

    return new Promise((resolve) => {
      try {
        const oCertificate = getCertificateObject(certThumbprint);
        const hasKey = oCertificate.HasPrivateKey();
        const oParesedSubj = string2dn(oCertificate.SubjectName);
        const oInfo = {
          HasPrivateKey: hasKey,
          IsValid: options.checkValid
            ? oCertificate.IsValid().Result
            : undefined,
          IssuerName: oCertificate.IssuerName,
          Issuer: string2dn(oCertificate.IssuerName),
          SerialNumber: oCertificate.SerialNumber,
          SubjectName: oCertificate.SubjectName,
          Subject: oParesedSubj,
          Name: oParesedSubj.CN,
          Thumbprint: oCertificate.Thumbprint,
          ValidFromDate: new Date(oCertificate.ValidFromDate),
          ValidToDate: new Date(oCertificate.ValidToDate),
          Version: oCertificate.Version,
          Algorithm: oCertificate.PublicKey().Algorithm.FriendlyName,
          ProviderName: (hasKey && oCertificate.PrivateKey.ProviderName) || "",
          ProviderType:
            (hasKey && oCertificate.PrivateKey.ProviderType) || undefined,
        };
        if (!options.checkValid) {
          const dt = new Date();
          oInfo.IsValid = dt >= oInfo.ValidFromDate && dt <= oInfo.ValidToDate;
        }
        oInfo.toString = infoToString;
        resolve(oInfo);
      } catch (e) {
        const err = getError(e);
        throw new Error(err);
      }
    });
  };

  /**
   * Получение массива доступных сертификатов
   * @returns {Promise<{id: string; name: string; subject: DN; validFrom: Date; validTo: Date;}[]>} [{id, name, subject, validFrom, validTo}, ...]
   */
  this.listCertificates = function () {
    const tryContainerStore = hasContainerStore();

    if (canAsync) {
      let oStore;
      let ret;
      return cadesplugin
        .then(() => cadesplugin.CreateObjectAsync("CAPICOM.Store"))
        .then((store) => {
          oStore = store;
          return oStore.Open(
            cadesplugin.CAPICOM_CURRENT_USER_STORE,
            cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
          );
        })
        .then(() => fetchCertsFromStore(oStore))
        .then((certs) => {
          ret = certs;
          return oStore.Close();
        })
        .then(() => {
          if (tryContainerStore) {
            let certificates;
            return oStore
              .Open(cadesplugin.CADESCOM_CONTAINER_STORE)
              .then(() => {
                const skipIds = ret.map((a) => a.id);
                return fetchCertsFromStore(oStore, skipIds);
              })
              .then((certs) => {
                certificates = certs;
                return oStore.Close();
              })
              .then(() => certificates)
              .catch((e) => {
                console.log(e);
                return [];
              });
          }

          return [];
        })
        .then((certs) => {
          ret.push(...certs);
          return ret;
        })
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }

    return new Promise((resolve) => {
      try {
        const oStore = cadesplugin.CreateObject("CAPICOM.Store");
        oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        );
        const ret = fetchCertsFromStore(oStore);
        oStore.Close();

        if (tryContainerStore) {
          try {
            oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
            const skipIds = ret.map((a) => a.id);
            const certs = fetchCertsFromStore(oStore, skipIds);
            oStore.Close();
            ret.push(...certs);
          } catch (e) {
            console.log(e);
          }
        }
        resolve(ret);
      } catch (e) {
        const err = getError(e);
        throw new Error(err);
      }
    });
  };

  /**
   * Чтение сертификата
   * @param {string} certThumbprint
   * @returns {Promise<string>} base64
   */
  this.readCertificate = function (certThumbprint) {
    if (canAsync) {
      return getCertificateObject(certThumbprint)
        .then((cert) => cert.Export(cadesplugin.CADESCOM_ENCODE_BASE64))
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }

    return new Promise((resolve) => {
      try {
        const oCertificate = getCertificateObject(certThumbprint);
        const data = oCertificate.Export(cadesplugin.CADESCOM_ENCODE_BASE64);
        resolve(data);
      } catch (e) {
        const err = getError(e);
        throw new Error(err);
      }
    });
  };

  /**
   * Подпись данных отсоединенная или присоединенная
   * @param {string} dataBase64
   * @param {string} certThumbprint
   * @param {object} [options]
   * @param {string} [options.pin] будет запрошен, если отсутствует
   * @param {boolean} [options.attached] присоединенная подпись
   * @returns {Promise<string>} base64
   */
  this.signData = function (dataBase64, certThumbprint, options) {
    if (typeof options === "string") {
      // обратная совместимость с версией 2.3
      options = { pin: options };
    }
    if (!options) options = {};
    const { pin, attached } = options;
    if (canAsync) {
      let oCertificate;
      let oSigner;
      let oSignedData;
      return getCertificateObject(certThumbprint, pin)
        .then((certificate) => {
          oCertificate = certificate;
          return Promise.all([
            cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner"),
            cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData"),
          ]);
        })
        .then((objects) => {
          oSigner = objects[0];
          oSignedData = objects[1];
          return Promise.all([
            oSigner.propset_Certificate(oCertificate),
            oSigner.propset_Options(signerOptions),
            // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
            oSignedData.propset_ContentEncoding(
              cadesplugin.CADESCOM_BASE64_TO_BINARY,
            ),
          ]);
        })
        .then(() => oSignedData.propset_Content(dataBase64))
        .then(() =>
          oSignedData.SignCades(
            oSigner,
            cadesplugin.CADESCOM_CADES_BES,
            !attached,
          ),
        )
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }
  };

  const XmlDsigGost3411Url2012256 =
    "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256";
  const XmlDsigGost3410Url2012256 =
    "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256";

  /**
   * Подпись данных xml отсоединенная или присоединенная
   * @param {string} contentXML
   * @param {string} certThumbprint
   * @param {object} [options]
   * @param {string} [options.pin] будет запрошен, если отсутствует
   * @param {boolean} [options.attached] присоединенная подпись
   * @returns {Promise<string>} base64
   */
  this.signDataXML = function (contentXML, certThumbprint, options) {
    if (typeof options === "string") {
      // обратная совместимость с версией 2.3
      options = { pin: options };
    }
    if (!options) options = {};
    const { pin, attached } = options;
    if (canAsync) {
      let oCertificate;
      let oSigner;
      let oSignedXML;
      return getCertificateObject(certThumbprint, pin)
        .then((certificate) => {
          oCertificate = certificate;
          return Promise.all([
            cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner"),
            cadesplugin.CreateObjectAsync("CAdESCOM.SignedXML"),
          ]);
        })
        .then((objects) => {
          oSigner = objects[0];
          oSignedXML = objects[1];
          return Promise.all([
            oSigner.propset_Certificate(oCertificate),
            // Error: Отсутствует сертификат УЦ в хранилище корневых сертификатов [0x800B0109] - если true
            oSigner.propset_CheckCertificate(false),
            // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
            oSignedXML.propset_Content(contentXML),
            oSignedXML.propset_SignatureType(
              cadesplugin.CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED,
            ),
            oSignedXML.propset_SignatureMethod(XmlDsigGost3410Url2012256),
            oSignedXML.propset_DigestMethod(XmlDsigGost3411Url2012256),
          ]);
        })
        .then(() => oSignedXML.Sign(oSigner))
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }

    return new Promise((resolve) => {
      try {
        const oCertificate = getCertificateObject(certThumbprint, pin);
        const oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
        oSigner.Certificate(oCertificate);
        oSigner.CheckCertificate(true);
        const oSignedXML = cadesplugin.CreateObject("CAdESCOM.SignedXML");
        // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
        oSignedXML.Content(contentXML);
        oSignedXML.SignatureType(
          cadesplugin.CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED,
        );
        oSignedXML.SignatureMethod(XmlDsigGost3410Url2012256);
        oSignedXML.DigestMethod(XmlDsigGost3411Url2012256);

        const sSignedMessage = oSignedXML.Sign(oSigner);
        resolve(sSignedMessage);
      } catch (e) {
        const err = getError(e);
        throw new Error(err);
      }
    });
  };

  /**
   * Проверить подпись XML.
   * @param {string} contentXML фрагмент xml с подписью
   */
  this.verifySignedXML = function (contentXML) {
    if (canAsync) {
      return cadesplugin
        .then(() => cadesplugin.CreateObjectAsync("CAdESCOM.SignedXML"))
        .then((oSignedXML2) => oSignedXML2.Verify(contentXML))
        .then(() => true)
        .catch((e) => e);
    }
    notification.error({
      message:
        "в данный момент библиотека не поддерживает синхронную версию проверки, попробуйте другой браузер или обновить расширение",
    });
  };

  /**
   * Проверить подпись.
   * @param {string} dataBase64 игнорируется если прикрепленная подпись
   * @param {string} signBase64 существующая подпись
   * @param {object} [options]
   * @param {boolean} [options.attached] присоединенная подпись
   * @returns {Promise<boolean>} true или reject
   */
  this.verifySign = function (dataBase64, signBase64, options) {
    if (!options) options = {};
    const { attached } = options;
    if (canAsync) {
      let oSignedData;
      return cadesplugin
        .then(() => cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData"))
        .then((object) => {
          oSignedData = object;
          if (attached) {
            return Promise.resolve();
          }

          // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
          return oSignedData
            .propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY)
            .then(() => oSignedData.propset_Content(dataBase64));
        })
        .then(() =>
          oSignedData.VerifyCades(
            signBase64,
            cadesplugin.CADESCOM_CADES_BES,
            !attached,
          ),
        )
        .then(
          () =>
            // console.log('sign2: %s', sign2);
            true,
        )
        .catch((e) => {
          const err = getError(e);
          throw new Error(err);
        });
    }

    return new Promise((resolve) => {
      try {
        const oSignedData = cadesplugin.CreateObject(
          "CAdESCOM.CadesSignedData",
        );
        if (!attached) {
          // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
          oSignedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
          oSignedData.Content = dataBase64;
        }
        oSignedData.VerifyCades(
          signBase64,
          cadesplugin.CADESCOM_CADES_BES,
          !attached,
        );
        resolve(true);
      } catch (e) {
        const err = getError(e);
        throw new Error(err);
      }
    });
  };

  function hasContainerStore() {
    // В версии плагина 2.0.13292+ есть возможность получить сертификаты из
    // закрытых ключей и не установленных в хранилище
    // но не смотря на это, все равно приходится собирать список сертификатов
    // старым и новым способом тк в новом будет отсутствовать часть старого
    // предположительно ГОСТ-2001 с какими-то определенными Extended Key Usage OID

    return versionCompare(pluginVersion, "2.0.13292") >= 0;
  }

  function fetchCertsFromStore(oStore, skipIds = []) {
    if (canAsync) {
      let oCertificates;
      return oStore.Certificates.then((certificates) => {
        oCertificates = certificates;
        return certificates.Count;
      })
        .then((count) => {
          const certs = [];
          for (let i = 1; i <= count; i++) certs.push(oCertificates.Item(i));
          return Promise.all(certs);
        })
        .then((certificates) => {
          const certs = [];
          for (const i in certificates)
            certs.push(
              certificates[i].SubjectName,
              certificates[i].Thumbprint,
              certificates[i].ValidFromDate,
              certificates[i].ValidToDate,
            );
          return Promise.all(certs);
        })
        .then((data) => {
          const certs = [];
          for (let i = 0; i < data.length; i += 4) {
            const id = data[i + 1];
            if (skipIds.indexOf(id) + 1) break;
            const oDN = string2dn(data[i]);
            certs.push({
              id,
              name: formatCertificateName(oDN),
              subject: oDN,
              validFrom: new Date(data[i + 2]),
              validTo: new Date(data[i + 3]),
            });
          }
          return certs;
        });
    }

    const oCertificates = oStore.Certificates;
    const certs = [];
    for (let i = 1; i <= oCertificates.Count; i++) {
      const oCertificate = oCertificates.Item(i);
      const id = oCertificate.Thumbprint;
      if (skipIds.indexOf(id) + 1) break;
      const oDN = string2dn(oCertificate.SubjectName);
      certs.push({
        id,
        name: formatCertificateName(oDN),
        subject: oDN,
        validFrom: new Date(oCertificate.ValidFromDate),
        validTo: new Date(oCertificate.ValidToDate),
      });
    }
    return certs;
  }

  function findCertInStore(oStore, certThumbprint) {
    if (canAsync) {
      return oStore.Certificates.then((certificates) =>
        certificates.Find(
          cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
          certThumbprint,
        ),
      ).then((certificates) =>
        certificates.Count.then((count) => {
          if (count === 1) {
            return certificates.Item(1);
          }

          return null;
        }),
      );
    }

    const oCertificates = oStore.Certificates.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      certThumbprint,
    );
    if (oCertificates.Count === 1) {
      return oCertificates.Item(1);
    }

    return null;
  }

  function getCertificateObject(certThumbprint, pin) {
    if (canAsync) {
      let oStore;
      let oCertificate;
      return cadesplugin
        .then(() => cadesplugin.CreateObjectAsync("CAPICOM.Store")) // TODO: CADESCOM.Store ?
        .then((o) => {
          oStore = o;
          return oStore.Open(
            cadesplugin.CAPICOM_CURRENT_USER_STORE,
            cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
          );
        })
        .then(() => findCertInStore(oStore, certThumbprint))
        .then((cert) =>
          oStore.Close().then(() => {
            if (!cert && hasContainerStore())
              return oStore
                .Open(cadesplugin.CADESCOM_CONTAINER_STORE)
                .then(() => findCertInStore(oStore, certThumbprint))
                .then((c) => oStore.Close().then(() => c));
            return cert;
          }),
        )
        .then((certificate) => {
          if (!certificate) {
            throw new Error(
              `Не обнаружен сертификат c отпечатком ${certThumbprint}`,
            );
          }
          return (oCertificate = certificate);
        })
        .then(() => oCertificate.HasPrivateKey())
        .then((hasKey) => {
          let p = Promise.resolve();
          if (hasKey && pin) {
            p = p
              .then(() => oCertificate.PrivateKey)
              .then((privateKey) =>
                Promise.all([
                  privateKey.propset_KeyPin(pin || ""),
                  privateKey.propset_CachePin(binded),
                ]),
              );
          }
          return p;
        })
        .then(() => oCertificate);
    }

    let oCertificate;
    const oStore = cadesplugin.CreateObject("CAPICOM.Store");
    oStore.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
    );
    oCertificate = findCertInStore(oStore, certThumbprint);
    oStore.Close();

    if (!oCertificate && hasContainerStore()) {
      oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
      oCertificate = findCertInStore(oStore, certThumbprint);
      oStore.Close();
    }

    if (!oCertificate) {
      throw new Error(`Не обнаружен сертификат c отпечатком ${certThumbprint}`);
    }

    if (oCertificate.HasPrivateKey && pin) {
      oCertificate.PrivateKey.KeyPin = pin || "";
      if (oCertificate.PrivateKey.CachePin !== undefined) {
        // возможно не поддерживается в ИЕ
        // https://www.cryptopro.ru/forum2/default.aspx?g=posts&t=10170
        oCertificate.PrivateKey.CachePin = binded;
      }
    }
    return oCertificate;
  }

  /**
   * Получить текст ошибки
   * @param {Error} e
   * @returns {string}
   */
  function getError(e) {
    console.log("Crypto-Pro error", e.message || e);
    if (e.message) {
      for (const i in cadesErrorMesages) {
        if (cadesErrorMesages.hasOwnProperty(i)) {
          if (e.message.indexOf(i) + 1) {
            e.message = cadesErrorMesages[i];
            break;
          }
        }
      }
    }
    return e.message || e;
  }

  /**
   * Разобрать субъект в объект DN
   * @param {string} subjectName
   * @returns {DN}
   */
  function string2dn(subjectName) {
    const dn = new DN();
    let pairs = subjectName.match(
      /([а-яёА-ЯЁa-zA-Z0-9\.\s]+)=(?:("[^"]+?")|(.+?))(?:,|$)/g,
    );
    if (pairs) pairs = pairs.map((el) => el.replace(/,$/, ""));
    else pairs = []; // todo: return null?
    pairs.forEach((pair) => {
      const d = pair.match(/([^=]+)=(.*)/);
      if (d && d.length === 3) {
        const rdn = d[1].trim().replace(/^OID\./, "");
        dn[rdn] = d[2]
          .trim()
          .replace(/^"(.*)"$/, "$1")
          .replace(/""/g, '"');
      }
    });
    return convertDN(dn);
  }

  /**
   * Собрать DN в строку пригодную для CX500DistinguishedName.Encode
   * @see https://docs.microsoft.com/en-us/windows/win32/api/certenroll/nf-certenroll-ix500distinguishedname-encode
   * @see https://www.cryptopro.ru/sites/default/files/products/cades/demopage/async_code.js
   * @see https://testgost2012.cryptopro.ru/certsrv/async_code.js
   * @param {DN} dn
   * @returns {string}
   */
  function dnToX500DistinguishedName(dn) {
    let ret = "";
    for (const i in dn) {
      if (dn.hasOwnProperty(i)) {
        ret += `${i}="${dn[i].replace(/"/g, '""')}", `;
      }
    }
    return ret;
  }

  /**
   * Получить название сертификата
   * @param {DN} o объект, включающий в себя значения субъекта сертификата
   * @see convertDN
   * @returns {String}
   */
  function formatCertificateName(o) {
    return `${o.CN}${o.INNLE ? `; ИНН ЮЛ ${o.INNLE}` : ""}${
      o.INN ? `; ИНН ${o.INN}` : ""
    }${o.SNILS ? `; СНИЛС ${o.SNILS}` : ""}`;
  }

  /**
   * https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array/28227607#28227607
   * @param {string} str
   * @returns {Array}
   */
  function stringToUtf8ByteArray(str) {
    // TODO(user): Use native implementations if/when available
    const out = [];
    let p = 0;
    for (let i = 0; i < str.length; i++) {
      let c = str.charCodeAt(i);
      if (c < 128) {
        out[p++] = c;
      } else if (c < 2048) {
        out[p++] = (c >> 6) | 192;
        out[p++] = (c & 63) | 128;
      } else if (
        (c & 0xfc00) == 0xd800 &&
        i + 1 < str.length &&
        (str.charCodeAt(i + 1) & 0xfc00) == 0xdc00
      ) {
        // Surrogate Pair
        c = 0x10000 + ((c & 0x03ff) << 10) + (str.charCodeAt(++i) & 0x03ff);
        out[p++] = (c >> 18) | 240;
        out[p++] = ((c >> 12) & 63) | 128;
        out[p++] = ((c >> 6) & 63) | 128;
        out[p++] = (c & 63) | 128;
      } else {
        out[p++] = (c >> 12) | 224;
        out[p++] = ((c >> 6) & 63) | 128;
        out[p++] = (c & 63) | 128;
      }
    }
    return out;
  }
}

export default CryptoPro;
