import { createContext } from "react";
import { PureAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { Actions, Subjects } from "@shared/lib/casl/types";

export type AppAbility = PureAbility<[Actions, Subjects]>;

const defaultAbility = new PureAbility<[Actions, Subjects]>();
export const AbilityContext = createContext<AppAbility>(defaultAbility);
export const Can = createContextualCan<AppAbility>(AbilityContext.Consumer);
