import { Tabs } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/shared/lib/react-router";
import "./journal-tabs.scss";
import { useCoordinator } from "@/shared/lib/hooks";

const getTabs = (projectId: string) => [
  {
    key: routes.journals.title(projectId),
    label: "Титульный лист",
  },
  {
    key: routes.journals.section1(projectId),
    label: "Раздел 1",
  },
  {
    key: "4",
    label: "Раздел 2",
  },
  {
    key: "5",
    label: "Раздел 3",
  },
  {
    key: "6",
    label: "Раздел 4",
  },
  {
    key: "7",
    label: "Раздел 5",
  },
  {
    key: "8",
    label: "Раздел 6",
  },
];

export const JournalTabs = () => {
  const navigate = useNavigate();
  const { projectId, mainJournalId } = useCoordinator() as {
    projectId: string;
    mainJournalId: string;
  };
  const location = useLocation();
  const tabs = getTabs(projectId || "");

  const defaultActiveKey = tabs.reduce((bestMatch, tab) => {
    return location.pathname.startsWith(tab.key) &&
      tab.key.length > (bestMatch?.length || 0)
      ? tab.key
      : bestMatch;
  }, "");

  const onTabChange = (key: string) => {
    navigate(key);
  };
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={tabs}
      className="project-custom-tabs project-custom-tabs-blue"
      onChange={onTabChange}
      tabBarStyle={{
        overflow: "hidden",
      }}
      tabBarGutter={32}
      style={{ marginBottom: "32px" }}
    />
  );
};
