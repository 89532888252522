import SkeletonNode from "antd/es/skeleton/Node";

export const ProjectObjectsSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "32px",
        width: "100%",
        position: "relative",
        backgroundColor: "#EAEEF8",
      }}
    >
      <SkeletonNode
        active
        style={{ width: 128, height: 22, marginBottom: 17 }}
      />
      <SkeletonNode
        active
        style={{ width: 256, height: 32, marginBottom: 12 }}
      />
      <SkeletonNode
        active
        style={{ width: "100%", height: 50, marginBottom: 32 }}
      />
      <SkeletonNode style={{ width: "100%", height: "20vh" }} />
    </div>
  );
};
