import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DaDataService, GetDaDataSuggestRequest } from "../api";

export function useDaDataSuggestion(request: GetDaDataSuggestRequest) {
  return useQuery({
    queryFn: () => DaDataService.getDaDataSuggestParty(request),
    queryKey: ["dadata", "suggest", "party", request],
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });
}
