import { Button, Input, Typography } from "antd";
import { useForm, useFieldArray } from "react-hook-form";
import "./representation-form-author.scss";

interface Section {
  section: string;
  number: string;
}

export const RepresentationFormAuthor = () => {
  const { control, register } = useForm({
    defaultValues: {
      sections: [{ section: "", number: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
  });


  const handleAddSection = () => {
    append({ section: "", number: "" });
  };

  return (
    <>
      <Typography.Title
        level={5}
        style={{ marginTop: "32px", marginBottom: "16px" }}
      >
        Сведения о разделах проектной документации, подготовленных этим лицом
      </Typography.Title>
      {fields.map((field, index) => (
        <div key={field.id} className="author-inputs-container">
          <div className="author-input-main-container">
            <div className="input-labale-text">Раздел</div>
            <Input
              placeholder=""
              {...register(`sections.${index}.section`, { required: true })}
            />
          </div>
          <div className="author-input-number-container">
            <div className="input-labale-text">Номер</div>
            <div className="author-input-number-wrapper">
              <Input
                className="author-input-number"
                {...register(`sections.${index}.number`, { required: true })}
              />
              {index === fields.length - 1 ? (
                <Button
                  type="default"
                  onClick={handleAddSection}
                >
                  Добавить
                </Button>
              ) : (
                <Button
                  type="text"
                  danger
                  onClick={() => remove(index)}
                  style={{ marginLeft: "8px" }}
                >
                  Удалить
                </Button>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
