import { useState } from "react";
import { Layout } from "antd";
import { NavigationContracts } from "./navigation-contracts";
import { NavigationMenu } from "./navigation-menu";
import { NavigationProject } from "./navigation-project";
import "./navigation.scss";
import { useCoordinator } from "@shared/lib/hooks";
import { useSuspenseQuery } from "@tanstack/react-query";
import { projectQueries } from "@entities/project/api/project.queries.ts";

const { Sider } = Layout;

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { projectId, mainJournalId } = useCoordinator() as {
    projectId: string;
    mainJournalId: string;
  };
  const { data: project } = useSuspenseQuery(
    projectQueries.getProject({ projectId }),
  );
  return (
    <Sider
      width={240}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <NavigationProject
        projectId={projectId || ""}
        collapsed={collapsed}
        projectName={project.shortName}
        projectDescription={project.fullName}
      />
      <NavigationMenu
        projectId={projectId || ""}
        collapsed={collapsed}
        mainJournalId={mainJournalId}
      />
      <NavigationContracts collapsed={collapsed} />
    </Sider>
  );
};

export default Navigation;
