import { memo } from "react";
import { DaDataParty } from "../types";
import "./party-select-option.scss";

const PartySelectOption = memo(({ value }: { value: DaDataParty }) => (
  // className контейнера находится выше (options className)
  <>
    <span className="party-select-option-title">{value.name.short}</span>
    <br />
    <span className="party-select-option-subtitle">{`ИНН: ${value.inn}, КПП: ${value.kpp}, ОГРН: ${value.ogrn}`}</span>
  </>
));

export default PartySelectOption;
