import React, { useState } from "react";
import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

interface InputDateProps {
  title?: string;
  style?: React.CSSProperties;
  onChange?: (dates: [Dayjs | null, Dayjs | null]) => void;
  defaultDates?: [Dayjs | null, Dayjs | null];
}

export const InputDate: React.FC<InputDateProps> = ({
  title = "Выберите диапазон дат",
  style,
  onChange,
  defaultDates = [null, null],
}) => {
  const [selectedDates, setSelectedDates] =
    useState<[Dayjs | null, Dayjs | null]>(defaultDates);

  const handleChange = (dates: [Dayjs | null, Dayjs | null]) => {
    setSelectedDates(dates);
    onChange?.(dates);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      {title && <span style={{ marginRight: "8px", fontSize: '12px' }}>{title}</span>}
      <RangePicker
        value={selectedDates}
        format="DD.MM.YYYY"
      />
    </div>
  );
};

