import { BellOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Flex, Space } from "antd";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useModalStore } from "@features/notifications";
import { TaskIcon } from "@shared/assets/svg/icons";
import { TabsMenu } from "./tabs-menu";
import "./header.scss";
// eslint-disable-next-line import/order
import { env } from "@shared/config.ts";

export const UserHeader = () => {
  const { isOpen, setIsOpen, setIsClose } = useModalStore();
  const handleModalStatus = () => {
    if (isOpen) {
      setIsClose();
    } else {
      setIsOpen();
    }
  };
  // TODO: адаптив для устройств
  const logout = () => {
    window.location.href = `${env.OLD_FRONTEND_URL}/logout?redirectTo=${env.ACTUAL_FRONTEND}`;
    return 0;
  };
  const items: MenuProps["items"] = [
    {
      key: 1,
      label: <div>Профиль</div>,
    },
    {
      key: 2,
      label: <div onClick={logout}>Выйти</div>,
    },
  ];
  return (
    <header className="header-container">
      <Link to="/projects" style={{ marginBottom: "8px" }}>
        <img
          src="https://builddocs.online/assets/img/logo.svg"
          alt="на главную"
        />
      </Link>
      <p className="absolute-version">2.0 - api preview</p>

      <TabsMenu className="header-tabs" />

      <Flex style={{ marginBottom: "8px" }}>
        <Space>
          <TaskIcon
            className={`anticon mr-28 task-icon ${isOpen ? "active" : ""}`}
            width={24}
            height={24}
            onClick={handleModalStatus}
          />
        </Space>
        <Space>
          <BellOutlined
            style={{
              fontSize: "24px",
              cursor: "pointer",
              marginRight: "28px",
            }}
            className="anticon mr-28"
          />
        </Space>
        <Dropdown menu={{ items }}>
          <Flex onClick={(e) => e.preventDefault()}>
            <Space>
              <Flex vertical style={{ textAlign: "end", cursor: "pointer" }}>
                <p style={{ color: "rgb(2, 14, 23)" }}>Example example</p>
                <p style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.6)" }}>
                  Organization position
                </p>
              </Flex>
              <Avatar size={28} icon={<UserOutlined />} />
            </Space>
          </Flex>
        </Dropdown>
      </Flex>
    </header>
  );
};
