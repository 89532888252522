import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, notification, Row, Typography } from "antd";
import { useAddCompanyToProject } from "@entities/project";
import { PartySuggestions, type DaDataParty } from "@features/dadata-input";
import { getErrorMessage } from "@shared/api/utils";
import { useCoordinator } from "@shared/lib/hooks";

const { Title, Text } = Typography;

export function AddOrganizationWindow() {
  const [selectedOrganization, setSelectedOrganization] =
    useState<DaDataParty | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: addCompany } = useAddCompanyToProject();

  const { projectId = "" } = useCoordinator();

  const handleClearSelected = () => {
    setSelectedOrganization(null);
  };

  const onButtonClick = async () => {
    if (projectId && selectedOrganization) {
      try {
        setIsLoading(true);
        await addCompany({
          projectId,
          company: selectedOrganization,
        });
        setSelectedOrganization(null);
      } catch (error) {
        const message = getErrorMessage(error);
        notification.error({
          message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="project-members-left-tab-container">
      <Card style={{ borderRadius: "16px", backgroundColor: "white" }}>
        <Title level={4} style={{ marginBottom: "24px" }}>
          Добавление организации
        </Title>
        <div style={{ marginBottom: "24px" }}>
          <div style={{ color: "#333333", marginBottom: "4px" }}>
            Организация
          </div>
          <PartySuggestions
            style={{
              display: "block",
            }}
            onPartySelect={setSelectedOrganization}
          />
        </div>
        {selectedOrganization ? (
          <div className="project-selected-container">
            <div className="project-selected-header">
              <div className="project-selected-organization-name">
                {selectedOrganization?.name.short}
              </div>
              <CloseOutlined
                onClick={handleClearSelected}
                className="project-selected-close-icon"
              />
            </div>
            <Row className="project-selected-detail">
              <Col span={6} className="project-selected-label">
                <Text type="secondary">ИНН</Text>
              </Col>
              <Col span={18}>{selectedOrganization?.inn}</Col>
            </Row>
            <Row className="project-selected-detail">
              <Col span={6} className="project-selected-label">
                <Text type="secondary">КПП</Text>
              </Col>
              <Col span={18}>{selectedOrganization?.kpp}</Col>
            </Row>
            <Row className="project-selected-detail">
              <Col span={6} className="project-selected-label">
                <Text type="secondary">ОГРН</Text>
              </Col>
              <Col span={18}>{selectedOrganization?.ogrn}</Col>
            </Row>
            <Row className="project-selected-detail">
              <Col span={6} className="project-selected-label">
                <Text type="secondary">Юр. адрес</Text>
              </Col>
              <Col span={18}>{selectedOrganization?.address.value}</Col>
            </Row>
          </div>
        ) : null}
        <Button
          className="project-members-add-member-button"
          type="primary"
          block
          loading={isLoading}
          iconPosition="end"
          disabled={!selectedOrganization}
          onClick={onButtonClick}
        >
          Добавить организацию
        </Button>
      </Card>
    </div>
  );
}
