import "./signature-table.scss";
import React, { useState } from "react";
import { Row, Col, Button, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface SignatureData {
  id: number;
  organization: string;
  employee: string;
  expirationDate: string;
  classification: string;
  textPermissions: string;
}

interface SignatureTableProps {
  data: SignatureData;
  selectedId: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
}

export const SignatureTable: React.FC<SignatureTableProps> = ({
  data,
  selectedId,
  setSelectedId,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const handleSelect = () => {
    setSelectedId(data.id);
  };

  return (
    <div
      className={`table-container table-container-signature ${
        selectedId === data.id ? "selected" : ""
      }`}
      onClick={handleSelect}
    >
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <Text style={{ color: "#888" }}>Организация</Text>
        </Col>
        <Col span={16}>
          <Text>{data.organization}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <Text style={{ color: "#888" }}>Сотрудник</Text>
        </Col>
        <Col span={16}>
          <Text>{data.employee}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <Text style={{ color: "#888" }}>Срок действия до</Text>
        </Col>
        <Col span={16}>
          <Text>{data.expirationDate}</Text>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Button
            type="link"
            onClick={handleToggle}
            style={{ padding: 0, color: "#1890ff", fontSize: "14px" }}
          >
            {expanded ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Полномочия
                <UpOutlined style={{ marginLeft: "4px" }} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Полномочия
                <DownOutlined style={{ marginLeft: "4px" }} />
              </div>
            )}
          </Button>
        </Col>
      </Row>
      {expanded && (
        <div style={{ marginTop: "16px" }}>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Text style={{ color: "#888" }}>Из классификатора</Text>
            </Col>
            <Col span={16}>
              <Text>{data.classification}</Text>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Text style={{ color: "#888" }}>Текстовые полномочия</Text>
            </Col>
            <Col span={16}>
              <Text>{data.textPermissions}</Text>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

