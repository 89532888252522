import { buildsServiceApi, journalsServiceApi } from "@shared/api";

export const JournalsStatuses = {
  NEW: "Новый",
  IN_PROGRESS: "В процессе",
  DONE: "Завершен",
} as const;

export type JournalsStatuses = keyof typeof JournalsStatuses;

export type GetDraftJournalsRequest = {
  buildingObjectId: string;
  page?: number;
  limit?: number;
};

export interface TitleJournal {
  titleId: string;
  mainJournalNumber: string;
  noVersionId: string;
  noVersionHistoryId: string;
  status: JournalsStatuses;
}

export interface MainJournal {
  mainJournalId: string;
  status: JournalsStatuses;
  createDate: string;
  updateDate: string;
  titleJournal: TitleJournal;
}

export interface BuildingObject {
  buildingObjectId: string;
  projectId: string;
  shortName: string;
  fullName: string;
  descriptionProjects: string;
  descriptionStage: string;
  constructionType: string;
  buildingStart: string;
  buildingEnd: string;
  address: {
    addressId: string;
    addressType: string;
    addressString: string;
    countryId: number;
    regionId: number;
    districtName: string;
    districtCode: string;
  };
  mainJournals: MainJournal[];
}

export interface GetDraftJournalsResponse {
  total: number;
  page: number;
  limit: number;
  buildingObject: BuildingObject;
}

export const getDraftJournals = ({
  buildingObjectId,
  page = 1,
  limit = 20,
}: GetDraftJournalsRequest): Promise<GetDraftJournalsResponse> => {
  return journalsServiceApi
    .get<GetDraftJournalsResponse>("journals/main/no_version", {
      params: { buildingObjectId, page, limit },
    })
    .then((response) => response.data);
};

