import React, { useState } from "react";
import { Modal, Radio, Button, Typography, Col, Row, Divider } from "antd";
import "./signature-modal.scss";
import { InfoIcon, VectorIcon, WarningIcon } from "@/shared/assets/svg/icons";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { SignatureTable } from "../signature-table";
import { Link } from "react-router-dom";
import { SignatureModalDefault, SignatureModalSelect } from "./ui";

const { Text } = Typography;

const fields = [
  { label: "ИНН", value: "7839136848" },
  { label: "СНИЛС", value: "783901001" },
  { label: "Алгоритм", value: "ГОСТ Р 34.10-2012 256 Бит" },
  { label: "Выдан", value: "15.05.2022" },
  { label: "Владелец", value: "Застройщиков Иван Иванович" },
  { label: "Номер сертификата", value: "fb123123-abc1-1234-abc2-a1111xxx12a0" },
];

const mockData = [
  {
    id: 1,
    organization: "ООО “Застройщик”",
    employee: "Застройщиков Иван Иванович",
    expirationDate: "12.09.2024",
    classification: "Подписание не важных документов",
    textPermissions: "Доверяем Застройщикову выбирать алкоголь на праздники",
  },
  {
    id: 2,
    organization: "ООО “Дизайн-Винишко”",
    employee: "Иванов Петр Сергеевич",
    expirationDate: "15.08.2025",
    classification: "Работа с контрактами",
    textPermissions: "Доверяем управлять внутренними проектами компании",
  },
  {
    id: 3,
    organization: "ООО “СтройПроект”",
    employee: "Сидоров Алексей Викторович",
    expirationDate: "10.03.2026",
    classification: "Подписание всех договоров",
    textPermissions: "Доверяем подписывать любые официальные документы",
  },
];

export const SignatureModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  const [invalid, setInvalid] = useState(true);
  const [error, setError] = useState(false);
  const [stateData, setStateData] = useState(mockData.slice(0, 2));
  const [selectedId, setSelectedId] = useState(mockData[0].id);
  const [addingAttorney, setAddingAttorney] = useState(false);

  const addObject = () => {
    if (stateData.length < mockData.length) {
      const nextObject = mockData[stateData.length];
      setStateData((prev) => [...prev, nextObject]);
    }
  };
  const handleInvalidTogle = () => {
    setInvalid((prev) => !prev);
  };
  const handleErrorTogle = () => {
    setError((prev) => !prev);
  };
  const handleCloseAddingAttorney = () => {
    setAddingAttorney(false);
  };
  return (
    <Modal
      title={
        addingAttorney ? (
          <div
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
            onClick={handleCloseAddingAttorney}
          >
            <div className="table-settings-icon-container">
              <VectorIcon className="table-settings-icon" />
            </div>
            Добавление доверенности
          </div>
        ) : (
          <div>Подписание</div>
        )
      }
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={720}
      className="signature-modal"
    >
      {addingAttorney ? (
        <SignatureModalSelect
          addObject={addObject}
          setAddingAttorney={setAddingAttorney}
        />
      ) : (
        <SignatureModalDefault
          invalid={invalid}
          error={error}
          handleErrorTogle={handleErrorTogle}
          fields={fields}
          handleInvalidTogle={handleInvalidTogle}
          stateData={stateData}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setAddingAttorney={setAddingAttorney}
        />
      )}
    </Modal>
  );
};

