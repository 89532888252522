import { Button, Flex, Radio, Typography } from "antd";
import { useCryptographyStore } from "../../model";
import { CryptographyNotificationTypeList } from "../cryptography-notification";
import EmptyComponent from "./empty-component";
import LoadingComponent from "./loading-component";
import SelectedCard from "./selected-card/selected-card";

export const CertificateSelector = () => {
  const {
    certificates,
    errorList,
    isLoadingCerts,
    fetchCertificates,
    selectedCertificate,
    fetchSelectCertificate,
  } = useCryptographyStore();

  const isShowNotification =
    !isLoadingCerts &&
    (errorList.includes(
      CryptographyNotificationTypeList.StorageNotInitialized,
    ) ||
      (Array.isArray(certificates) && !certificates.length));

  return (
    <div style={{ marginTop: 16, position: "relative" }}>
      <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
        <Typography.Title
          level={5}
          style={{ marginBottom: 0, textAlign: "center" }}
        >
          Выберите сертификат
        </Typography.Title>
        <Button
          type="link"
          style={{ paddingRight: 0 }}
          onClick={fetchCertificates}
        >
          Обновить список
        </Button>
      </Flex>
      {isLoadingCerts && <LoadingComponent />}

      {!isLoadingCerts && certificates?.length && (
        <Radio.Group
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
          onChange={(v) => fetchSelectCertificate(v.target.value)}
          value={selectedCertificate?.Thumbprint}
          options={certificates?.map((cert) => {
            return {
              value: cert.id,
              label: `${cert.subject ? `${cert.subject.CN}, ИНН ${cert.subject.INN ?? "-"}, СНИЛС ${cert.subject.SNILS ?? "-"}` : cert.id}`,
            };
          })}
        />
      )}

      {selectedCertificate && <SelectedCard person={selectedCertificate} />}

      {isShowNotification && <EmptyComponent />}
    </div>
  );
};
