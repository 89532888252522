import { useMutation, useQueryClient } from "@tanstack/react-query";
// FIXME: импортировать из features в entities нельзя.
import { DaDataParty } from "@features/dadata-input/types";
import { ProjectQueriesTags } from "@shared/api/project";
import { CompanyService } from "../@x/company";
import { addParticipant } from "../api/add-participant";

export function useAddCompanyToProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      company,
      projectId,
    }: {
      company: DaDataParty;
      projectId: string;
    }) => {
      const companyServiceData = await CompanyService.findCompanyByInnKpp({
        inn: company.inn,
        kpp: company.kpp,
      });

      return addParticipant({
        projectId,
        body: {
          companyId: companyServiceData.companyId,
        },
      });
    },
    onSuccess: (_data, { projectId }) => {
      queryClient
        .invalidateQueries({
          queryKey: ProjectQueriesTags.getProjectParticipants({
            projectId: projectId ?? "",
          }),
        })
        .then();
    },
  });
}
