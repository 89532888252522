import { buildsServiceApi } from "@shared/api";
import { UserCompany } from "../@x/user.ts";

export type GetProjectAdminRequest = {
  projectId: string;
};
export type GetProjectAdminResponse = {
  projectId: string;
  userInfo: AdminUserInfo[];
};
export interface AdminUserInfo {
  userId: string;
  userFirstName: string;
  userSurname: string;
  userMiddleName: string;
  userMobilePhone: string;
  userEmail: string;
  userAvatarId: string | null;
  userCompanies: UserCompany[];
  isBlock: boolean;
}
export const getProjectAdmin = ({
  projectId,
}: GetProjectAdminRequest): Promise<GetProjectAdminResponse> => {
  return buildsServiceApi
    .get<GetProjectAdminResponse>(`/projects/${projectId}/admin`)
    .then((r) => r.data);
};
