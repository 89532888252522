import { Button, notification, Popconfirm } from "antd";
import { useRemoveProjectParticipant } from "@entities/project";
import { getErrorMessage } from "@shared/api/utils";
import CanIcon from "@shared/assets/svg/icons/del_16.svg?react";
import "./delete-organization-button.scss";
import { useCoordinator } from "@shared/lib/hooks";

interface DeleteOrganizationButtonProps {
  projectParticipantId: string;
}

export function DeleteOrganizationButton({
  projectParticipantId,
}: DeleteOrganizationButtonProps) {
  const { projectId } = useCoordinator() as { projectId: string };
  const { mutateAsync } = useRemoveProjectParticipant(projectId);

  const onClickHandler = async () => {
    try {
      await mutateAsync({
        projectParticipantId,
      });
    } catch (e) {
      const message = getErrorMessage(e);
      notification.error({
        message,
      });
    }
  };

  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить организацию?"
      onConfirm={onClickHandler}
    >
      <Button
        aria-label="Удалить организацию"
        className="delete-organization-button"
        icon={<CanIcon />}
      />
    </Popconfirm>
  );
}
