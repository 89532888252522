import { queryOptions } from "@tanstack/react-query";
import { getObjectSettings } from "./get-object-settings";

export const objectQueries = {
  rootKey: () => ["object"],

  getObjectSettings: ({ objectId }: any) =>
    queryOptions({
      queryKey: [...objectQueries.rootKey(), "getObjectSettings", objectId],
      queryFn: () => getObjectSettings({ objectId }),
    }),
};
