import { TableInfo, TableInfoType } from "@/features/table-info";
import "./journals-supervision-official.scss";

const tableMock: TableInfoType[] = [
  {
    id: 1,
    title: "ФИО",
    info: "Надзорный Олег Викторович",
  },
  {
    id: 2,
    title: "Должность",
    info: "Директор",
  },
  {
    id: 3,
    title: "Распорядительный документ, подтверждающий полномочия",
    info: "Распорядительный документ 12, 12.11.2024",
  },
];

export const JournalsSupervisionOfficial = () => {
  return (
    <TableInfo
      tabs={tableMock}
      name="Должностное лицо органа государственного строительного надзора"
      id="journals-supervision-official"
      editionalName="Информация о представителе"
    />
  );
};
