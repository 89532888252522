import { buildsServiceApi } from "@shared/api";
import { IObject } from "@/shared/api/object/types";

export const getObjectSettings = async ({
  objectId,
}: any): Promise<any> => {
  return buildsServiceApi
    .get<any>(
      `/objects/${objectId}/settings?idType=projectId`
    )
    .then((response) => response.data);
};
