import { useMutation, useQueryClient } from "@tanstack/react-query";
import { projectQueries, updateProject } from "@entities/project";

export function useUpdateProjectAvatar() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file, projectId }: { file: File; projectId: string }) =>
      updateProject({
        projectId,
        file,
        fileMetadata: {
          fileName: file.name,
          fileType: file.type,
          fileNumber: 1,
          fileContentLength: file.size,
        },
      }),
    onSuccess: (data, { projectId }) => {
      queryClient.setQueryData(
        projectQueries.getProject({ projectId }).queryKey,
        data,
      );
    },
  });
}
