import { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Typography } from "antd";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./project-journals.scss";
import { EllipsisOutlined } from "@ant-design/icons";
import { JournalTabs } from "@/features/journal-tabs/ui";
import { ProjectRelatedTabs } from "@/features/project-related-tabs";
import {
  HistoryIcon,
  SettingsIcon,
  VectorIcon,
  XmlIcon,
} from "@/shared/assets/svg/icons";
import PdfIcon from "@/shared/assets/svg/icons/pdf-icon.png";
import { useCoordinator } from "@/shared/lib/hooks";
import { routes } from "@/shared/lib/react-router";

const { Title } = Typography;

enum signStatusEnum {
  onSign = "На подписание",
  signed = "Подписан",
  rejected = "отклонен",
  comment = "Оставить замечание",
}

enum isJornalStatusEnum {
  isSigned = "подписан",
  isNotSigned = "не подписан",
}

export const STATUS_MAP = {
  new: { label: "Новый", textColor: "#000000", backgroundColor: "#FFFFFF" },
  in_progress: {
    label: "В работе",
    textColor: "#FFFFFF",
    backgroundColor: "#52C41A",
  },
  closed: {
    label: "Закрыт",
    textColor: "#FFFFFF",
    backgroundColor: "#BFBFBF",
  },
  pending_review: {
    label: "На проверке",
    textColor: "#FFFFFF",
    backgroundColor: "#FFC53D",
  },
  rejected: {
    label: "Отклонен",
    textColor: "#FFFFFF",
    backgroundColor: "#FF4D4F",
  },
  approved: {
    label: "Одобрен",
    textColor: "#FFFFFF",
    backgroundColor: "#1890FF",
  },
  awaiting_approval: {
    label: "На согласовании",
    textColor: "#FFFFFF",
    backgroundColor: "#91D5FF",
  },
  preparing_xml: {
    label: "Подготовка XML",
    textColor: "#FFFFFF",
    backgroundColor: "#D9D9D9",
  },
  signing: {
    label: "На подписании",
    textColor: "#FFFFFF",
    backgroundColor: "#FAAD14",
  },
  signed: {
    label: "Подписано",
    textColor: "#FFFFFF",
    backgroundColor: "#73D13D",
  },
  sent_to_gsn: {
    label: "Отправлено в ГСН",
    textColor: "#FFFFFF",
    backgroundColor: "#ADC6FF",
  },
  registered_in_gsn: {
    label: "Зарегистрировано в ГСН",
    textColor: "#FFFFFF",
    backgroundColor: "#87E8DE",
  },
  interaction_error: {
    label: "Ошибка взаимодействия",
    textColor: "#FFFFFF",
    backgroundColor: "#722ED1",
  },
  document_error: {
    label: "Ошибка документа",
    textColor: "#FFFFFF",
    backgroundColor: "#FF85C0",
  },
  gsn_remark: {
    label: "Замечание от ГСН",
    textColor: "#FFFFFF",
    backgroundColor: "#D48806",
  },
} as const;

const ROUTE_MAP = {
  title: "/title",
  settings: "/settings",
  history: "/history",
  notes: "/notes",
  details: "/details",
  section1: "/section1",
};

export type StatusType = {
  label: string;
  textColor: string;
  backgroundColor: string;
};
type journalInfoType = {
  message: {
    color: string;
    messageText: string;
    textButton: string;
    buttonLgoic: () => void;
  } | null;
  status: {
    prevStatusText: string;
    prevStatusColor: string;
    prevStatusBackground: string;
    statusText: string;
    statusColor: string;
    statusBackground: string;
  };
  isSignedTables: boolean;
  isHideButtons: boolean;
  isHideTableInfo: boolean;
  currentStatus: StatusType;
};

export const ProjectJournalsView = () => {
  const [containsTitle, setContainsTitle] = useState(true);
  const [containsSettings, setContainsSettings] = useState(false);
  const [containsHistory, setContainsHistory] = useState(false);
  const [containsNotes, setContainsNotes] = useState(false);
  const [containsDetails, setContainsDetails] = useState(false);
  const [containsSection1, setContainsSection1] = useState(false);

  const url = window.location.pathname;

  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, mainJournalId } = useCoordinator();

  const tabRoutes: Record<string, string> = {
    "Общие сведения": "title",
    Замечания: "notes",
    "Сведения о регистрации в ГСН": "details",
  };
  const tabs = Object.keys(tabRoutes);

  const getActiveTab = () => {
    const currentRoute = location.pathname.split("/").pop();
    const activeTab = Object.entries(tabRoutes).find(
      ([, route]) => route === currentRoute,
    );
    return activeTab ? activeTab[0] : "Общие сведения";
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());

  const handleNavigateTitle = () => {
    navigate(routes.journals.title(projectId));
  };

  const [_, setSearchParams] = useSearchParams();
  const handleOpen = () => {
    setSearchParams({ "settings-admin": "open" });
  };

  useEffect(() => {
    const route = tabRoutes[activeTab];
    if (route) {
      navigate(route);
    }
  }, [activeTab, navigate]);

  useEffect(() => {
    setContainsTitle(url.includes("/title"));
    setContainsSettings(url.includes("/settings"));
    setContainsHistory(url.includes("/history"));
    setContainsSection1(url.includes("/section1"));
    setContainsDetails(url.includes("/details"));
    setContainsNotes(url.includes("/notes"));
  }, [url]);

  const menu = (
    <Menu>
      {tabRoutes[activeTab] === "notes" && (
        <Menu.SubMenu
          key="notes"
          title={
            <Menu.Item key="3" icon={<i className="icon-xml" />}>
              Скачать XML
            </Menu.Item>
          }
        >
          <Menu.Item key="notes-1">%наим документа% + %дата%.xml</Menu.Item>
          <Menu.Item key="notes-2">%наим документа% + %дата%.xml</Menu.Item>
          <Menu.Item key="notes-3">%наим документа% + %дата%.xml</Menu.Item>
          <Menu.Item key="notes-4">%наим документа% + %дата%.xml</Menu.Item>
          <Menu.Item key="notes-5">%наим документа% + %дата%.xml</Menu.Item>
        </Menu.SubMenu>
      )}
      {tabRoutes[activeTab] === "details" && (
        <Menu.Item key="3" icon={<i className="icon-xml" />}>
          Скачать XML
        </Menu.Item>
      )}
      {tabRoutes[activeTab] === "title" && (
        <>
          <Menu.Item
            key="4"
            icon={<SettingsIcon />}
            onClick={() => navigate(routes.journals.settings(projectId))}
          >
            Настройки
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<HistoryIcon />}
            onClick={() => navigate(routes.journals.history(projectId))}
          >
            История
          </Menu.Item>
          <Menu.Item key="6" icon={<XmlIcon />}>
            Скачать .xml
          </Menu.Item>
          <Menu.Item
            key="7"
            icon={<img src={PdfIcon} alt="" width={16} height={16} />}
          >
            Скачать .pdf
          </Menu.Item>
          <Menu.Item key="8" icon={<SettingsIcon />} onClick={handleOpen}>
            Админка
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const [journalInfo, setJournalInfo] = useState<journalInfoType>({
    message: {
      color: "#4169e1",
      messageText:
        "Вас назначили %НазваниеРоли в объект %ИмяОбъекта. Подпишите назначение на Титульном листе Журнала общих работ",
      textButton: "Посмотреть",
      buttonLgoic: () => {
        console.log("Посмотреть");
      },
    },
    status: {
      prevStatusText: "Отклонен",
      prevStatusColor: "white",
      prevStatusBackground: "red",
      statusText: "Отклонен",
      statusColor: "#FFFFFF",
      statusBackground: "#F20404",
    },
    isSignedTables: false,
    isHideButtons: false,
    isHideTableInfo: false,
    currentStatus: {
      label: "Новый",
      textColor: "#000000",
      backgroundColor: "#FFFFFF",
    },
  });

  const [signStatus, setSignStatus] = useState(signStatusEnum.onSign);
  return (
    <div
      className="general-view-container"
      style={journalInfo?.message ? { paddingTop: "16px" } : {}}
    >
      {journalInfo?.message ? (
        <div
          style={{
            padding: "12px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: journalInfo.message.color,
            color: "white",
            alignItems: "center",
            borderRadius: "10px",
            marginBottom: "16px",
            paddingTop: "12px",
          }}
        >
          <div>{journalInfo.message.messageText}</div>
          <Button onClick={() => journalInfo?.message?.buttonLgoic()}>
            {journalInfo.message.textButton}
          </Button>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <Title level={3} style={{ marginBottom: "0px" }}>
            Загородный дом №1
          </Title>
          <div
            style={{
              backgroundColor: journalInfo.currentStatus.backgroundColor,
              fontSize: "12px",
              padding: "2px 6px 2px 6px",
              color: journalInfo.currentStatus.textColor,
              marginLeft: "14px",
              borderRadius: "5px",
            }}
          >
            {journalInfo.currentStatus.label
              ? journalInfo.currentStatus.label
              : null}
          </div>
        </div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            shape="circle"
            icon={<EllipsisOutlined />}
            style={{
              borderRadius: "10px",
            }}
          />
        </Dropdown>
      </div>
      <JournalTabs />{" "}
      {containsTitle || containsNotes || containsDetails ? (
        <>
          {containsSettings || containsHistory ? (
            <div
              className="table-container table-container-settings"
              onClick={handleNavigateTitle}
              style={containsHistory ? { marginBottom: "24px" } : {}}
            >
              <div className="table-settings-icon-container">
                <VectorIcon className="table-settings-icon" />
              </div>
              <Typography.Title level={4} style={{ marginBottom: "0px" }}>
                {containsSettings ? <>Настройки</> : null}
                {containsHistory ? <>История</> : null}
              </Typography.Title>
            </div>
          ) : (
            <div className="related-tabs-container">
              <ProjectRelatedTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                journalInfo={journalInfo}
              />
            </div>
          )}
        </>
      ) : null}
      <Outlet
        context={{ journalInfo, setJournalInfo, signStatus, setSignStatus }}
      />
    </div>
  );
};

export type { journalInfoType, isJornalStatusEnum };
export { signStatusEnum };
