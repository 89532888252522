import { Fragment, ReactNode } from "react";
import { Row, Col, Button, Typography } from "antd";
import "./table-info.scss";
import { Can, Subjects } from "@shared/lib/casl";
import { TableInfoType } from "../types";

const { Title } = Typography;

interface ITableInfo {
  tabs: TableInfoType[];
  name?: string;
  editionalName?: string;
  businessEntity?: Subjects;
  isButton?: boolean;
  buttonName?: string;
  handleIsButton?: () => void;
  children?: ReactNode;
  id?: string;
}

export const TableInfo = ({
  tabs,
  name,
  editionalName,
  isButton,
  buttonName,
  handleIsButton,
  businessEntity,
  children,
  id,
}: ITableInfo) => {
  return (
    <div className="table-container" id={id || ""}>
      {name ? (
        <div className="flex-between">
          <Title
            style={{
              marginBottom: "24px",
            }}
            level={4}
          >
            {name}
          </Title>
          {/* TODO: написать бы нормально business entity не всегда может быть, а если нету то не нужен Can */}
          {isButton && handleIsButton ? (
            businessEntity ? (
              <Can I="edit" a={businessEntity}>
                <Button type="default" style={{ backgroundColor: "#EAEEF8" }}>
                  {buttonName || "Редактировать"}
                </Button>
              </Can>
            ) : (
              <Button type="default" style={{ backgroundColor: "#EAEEF8" }}>
                {buttonName || "Редактировать"}
              </Button>
            )
          ) : null}
        </div>
      ) : null}
      {editionalName ? (
        <div
          style={{ fontSize: "16px", lineHeight: "24px", marginBottom: "24px" }}
        >
          {editionalName}
        </div>
      ) : null}
      <Row gutter={[16, 16]}>
        {children}
        {tabs.map(({ id, title, info }) => (
          <Fragment key={id}>
            <Col span={8} style={{ color: "#888888", lineHeight: "20px" }}>
              {title}
            </Col>
            <Col
              span={16}
              className="table-info-content"
              style={{ lineHeight: "20px" }}
            >
              {info}
            </Col>
          </Fragment>
        ))}
      </Row>
    </div>
  );
};
