import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { JournalsSectionFirst } from "./ui";

export const JournalsSectionFirstRoute = (
  ...rest: RouteObject[]
): RouteObject => {
  return {
    path: `${routes.journals.section1()}`,
    element: createElement(JournalsSectionFirst),
    children: [...rest],
  };
};

