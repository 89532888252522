// eslint-disable-next-line import/no-mutable-exports
import { message } from "antd";
import { InternalAxiosRequestConfig } from "axios";
import { UserService } from "@shared/api/user";

// eslint-disable-next-line import/no-mutable-exports
// export let ACCESSTOKEN = "";
const storageAcessTokenName = "newToken";
const storageIatName = "newIat";
// eslint-disable-next-line import/no-mutable-exports
// export let OLD_ACCESSTOKEN = "";
const storageOldAcessTokenName = "token";
const storageOldIatName = "iat";

export class TokenManipulate {
  static print(tokens: {
    newToken?: string;
    newIat?: string;
    refreshToken?: string;
    token?: string;
    iat?: string;
    oldRefreshToken?: string;
  }) {
    if (tokens.newToken) {
      localStorage.setItem(storageAcessTokenName, tokens.newToken);
    }
    if (tokens.newIat) {
      localStorage.setItem(storageIatName, String(Number(tokens.newIat) + 270));
    }
    if (tokens.token) {
      localStorage.setItem(storageOldAcessTokenName, tokens.token);
    }
    if (tokens.iat) {
      localStorage.setItem(storageOldIatName, String(Number(tokens.iat) + 270));
    }
    if (tokens.refreshToken) {
      console.log("attempt to do something with refresh token");
    }
    if (tokens.oldRefreshToken) {
      console.log("attempt to do something with old refresh token");
    }
  }

  static async refresh(refreshToken: string) {
    const messageKey = "updateToken";
    message.open({
      key: messageKey,
      content: "Обновление токена",
      type: "loading",
    });
    if (!refreshToken)
      return new Promise((_resolve, reject) => {
        reject();
      });
    return UserService.oldService_refreshToken(refreshToken)
      .then((tokens) => {
        this.print({
          token: tokens.data.jwtToken,
          newToken: tokens.data.keycloak.accessToken,
          iat: String(Math.floor(Date.now() / 1000)),
          newIat: String(Math.floor(Date.now() / 1000) + 270),
        });
        message.open({
          key: messageKey,
          content: "Токен обновлен",
          type: "success",
        });
      })
      .catch(() => {
        message.open({
          key: messageKey,
          content: "Не удалось обновить токен",
          type: "error",
        });
      });
  }

  static remove() {
    localStorage.removeItem(storageAcessTokenName);
    localStorage.removeItem(storageIatName);
    localStorage.removeItem(storageOldAcessTokenName);
    localStorage.removeItem(storageOldIatName);
  }
}

export const checkToken = async (
  config: InternalAxiosRequestConfig,
  settings = { useOldToken: false },
) => {
  const usableIatName = settings.useOldToken
    ? storageOldIatName
    : storageIatName;
  const usableTokenName = settings.useOldToken
    ? storageOldAcessTokenName
    : storageAcessTokenName;

  if (config.url === `/tokens/jwt/refresh`) return config;

  const iat = localStorage.getItem(usableIatName);
  const token = localStorage.getItem(usableTokenName);
  const oldToken = localStorage.getItem(storageOldAcessTokenName);

  // если токен есть
  if (iat && Number(iat) < Math.floor(Date.now() / 1000)) {
    localStorage.removeItem(usableIatName);
    return TokenManipulate.refresh(oldToken || "")
      .then(() => {
        const updatedToken = localStorage.getItem(usableTokenName);
        config.headers.Authorization = `Bearer ${updatedToken}`;
        return config;
      })
      .catch((err) => {
        console.error("Error refreshing token:", err);
        return config;
      });
  }

  // если токена и iat нет (для первого запроса, без синхронизации после авторизации на bd 1.0
  if (!iat && !token && oldToken) {
    localStorage.removeItem(usableIatName);
    return TokenManipulate.refresh(oldToken || "")
      .then(() => {
        const updatedToken = localStorage.getItem(usableTokenName);
        config.headers.Authorization = `Bearer ${updatedToken}`;
        return config;
      })
      .catch((err) => {
        console.error("Error refreshing token:", err);
        return config;
      });
  }

  if (config.headers?.Authorization?.toString() === "Bearer ") {
    return TokenManipulate.refresh(oldToken || "")
      .then(() => {
        const updatedToken = localStorage.getItem(usableTokenName);
        config.headers.Authorization = `Bearer ${updatedToken}`;
        return config;
      })
      .finally(() => config);
  }
  const currentToken = localStorage.getItem(usableTokenName);

  if (currentToken) {
    config.headers.Authorization = `Bearer ${currentToken}`;
    return config;
  }

  // if (
  //   (settings.useOldToken && OLD_ACCESSTOKEN) ||
  //   (settings.useOldToken && ACCESSTOKEN)
  // ) {
  //   console.log(
  //     "this if 147",
  //     settings.useOldToken,
  //     OLD_ACCESSTOKEN,
  //     ACCESSTOKEN,
  //   );
  //   // eslint-disable-next-line no-param-reassign
  //   config.headers.Authorization = `Bearer ${settings.useOldToken ? OLD_ACCESSTOKEN : ACCESSTOKEN}`;

  //   return config;
  // }
  return config;
};
