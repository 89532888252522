import type { ProjectCard } from "@entities/project";
import type { LegacyApi, ModernApi } from "@shared/api";

function coerceToGenericProject(project: any): ProjectCard {
  return {
    //@ts-ignore
    isModern: project.isModern,
    id: project.projectId,
    avatar: project.avatar ?? project.projectAvatarId,
    shortName: project.projectName ?? project.shortName,
    fullName: project.description ?? project.fullName,
    address: project.constructionAddress ?? project.addressUnstructured,
    externalId: project.externalId,
  };
}

export function mergeProjectArraysWithDedupe(
  legacyProjects: Array<LegacyApi.ProjectCardDto> = [],
  modernProjects: Array<ModernApi.ProjectCardDto> = [],
): Array<ProjectCard> {
  const projectsMap = new Map<string, ProjectCard>();

  legacyProjects.forEach((project) => {
    const newInstance = { ...project, isModern: false };
    const modified = coerceToGenericProject(newInstance);
    projectsMap.set(project.projectId, modified);
  });

  modernProjects.forEach((project) => {
    const newInstance = { ...project, isModern: true };
    const modified = coerceToGenericProject(newInstance);
    projectsMap.set(project.externalId ?? project.projectId, modified);
  });

  return [...projectsMap.values()];
}
