import { notification } from "antd";
import { create } from "zustand";
import {
  CertificateInfoInterface,
  CertListItemInterface,
  CryptoPro,
} from "@shared/lib/crypto-pro";
import { CryptographyNotificationTypeList } from "../ui/cryptography-notification";
// Используем локальную либу, но типы - из общей: ruscryptojs
// TODO найти типы и взять себе

type CryptographyStore = {
  isError: boolean;
  errorList: string[];

  isInitialized: boolean;
  init: () => void;

  isLoadingCerts: boolean;
  certificates: CertListItemInterface[] | undefined;
  fetchCertificates: () => void;

  isLoadingSelectedCertificate: boolean;
  selectedCertificate: CertificateInfoInterface | undefined;
  fetchSelectCertificate: (thumbPrint: string) => void;

  signXML: (contentXML: string, thumbPrint: string) => Promise<string>;
  verifyXML: (contentXML: string) => Promise<boolean>;

  signString: (base64: string, thumbPrint: string) => Promise<string>;
  verifyString: (base64: string, signBase64: string) => Promise<boolean>;
};

const cryptopro = new CryptoPro();

export const useCryptographyStore = create<CryptographyStore>((set) => ({
  isInitialized: false,

  isError: false,
  errorList: [],

  isLoadingCerts: false,
  certificates: undefined,

  isLoadingSelectedCertificate: false,
  selectedCertificate: undefined,

  init: async () => {
    set({
      isInitialized: false,
      certificates: undefined,
      selectedCertificate: undefined,
    });
    try {
      await cryptopro.init();
      set({ isInitialized: true });
    } catch (error) {
      set((state) => ({
        isError: true,
        isInitialized: false,
        errorList: [
          ...state.errorList,
          CryptographyNotificationTypeList.StorageNotInitialized,
        ],
      }));
    }
  },

  fetchCertificates: async () => {
    set({ isLoadingCerts: true, selectedCertificate: undefined });
    try {
      const certs = await cryptopro.listCertificates();
      set({ certificates: certs });
    } catch (error) {
      notification.error({
        message: "Произошла ошибка при получении сертификатов",
        placement: "topRight",
        description: String(error),
      });
    } finally {
      set({ isLoadingCerts: false });
    }
  },

  fetchSelectCertificate: async (thumbPrint: string) => {
    set({ isLoadingSelectedCertificate: true });
    try {
      const cert = await cryptopro.certificateInfo(thumbPrint);
      set({ selectedCertificate: cert });
    } catch (error) {
      notification.error({
        message: "Произошла ошибка при получении сертификатов",
        placement: "topRight",
        description: String(error),
      });
    } finally {
      set({ isLoadingSelectedCertificate: false });
    }
  },

  signXML: async (contentXML, thumbPrint) => {
    try {
      const data = await cryptopro.signDataXML(contentXML, thumbPrint, {
        attached: true,
      });
      console.log(data);
      return data;
    } catch (e) {
      notification.error({
        message: "Произошла ошибка при подписании xml",
        placement: "topRight",
        description: String(e),
      });
    }
  },
  verifyXML: async (contentXML) => {
    try {
      const data = await cryptopro.verifySignedXML(contentXML);
      console.log(data);
      return data;
    } catch (e) {
      notification.error({
        message: "Произошла ошибка при верификации подписи xml",
        placement: "topRight",
        description: String(e),
      });
    }
  },

  signString: async (base64: string, thumbPrint: string) => {
    try {
      const data = await cryptopro.signData(btoa(`${base64}`), thumbPrint);
      console.log(data);
      return data;
    } catch (e) {
      notification.error({
        message: "Произошла ошибка при подписании base64 string",
        placement: "topRight",
        description: String(e),
      });
    }
  },

  // не рабочая, тк signBase64 - не создаем пока что
  verifyString: async (base64: string, signBase64: string) => {
    try {
      const data = await cryptopro.verifySign(
        btoa(`${base64}`),
        btoa(`${signBase64}`),
      );
      console.log(data);
      return data;
    } catch (e) {
      notification.error({
        message: "Произошла ошибка при верификации base64 string",
        placement: "topRight",
        description: String(e),
      });
    }
  },
}));
