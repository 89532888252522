import { journalsServiceApi } from "@shared/api";

export type CreateJournalRequest = {
  buildingObjectId: string;
};

export type CreateJournalResponse = {
  mainJournalId: string;
  status: string;
  createDate: string;
  updateDate: string;
};

export const createJournal = async ({
  buildingObjectId,
}: CreateJournalRequest): Promise<CreateJournalResponse> => {
  const response = await journalsServiceApi.post<CreateJournalResponse>(
    "journals/main",
    { buildingObjectId },
  );

  return response.data;
};

export type DeleteJournalRequest = {
  mainJournalId: string;
};

export const deleteJournal = async ({
  mainJournalId,
}: DeleteJournalRequest): Promise<void> => {
  const response = await journalsServiceApi.delete(
    `journals/main/${mainJournalId}`,
  );

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Ошибка при удалении журнала");
  }
};

