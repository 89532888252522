import { buildsServiceApi } from "@shared/api";
import { ProjecSettingApi } from "@/shared/api/project/types";
import { ProjectSettingsRequestSchema } from "@/shared/api/project/contracts";

export const getProjectSettings = async ({
  projectId,
}: ProjecSettingApi.ProjectSettingsRequest): Promise<ProjecSettingApi.ProjectSettingsResponse> => {
  const validatedData = ProjectSettingsRequestSchema.parse({ projectId });
  return buildsServiceApi
    .get<ProjecSettingApi.ProjectSettingsResponse>(
      `/projects/${validatedData.projectId}/settings?idType=projectId`,
    )
    .then((response) => response.data);
};

