import { Dropdown, Tooltip } from "antd";
import "./user-info-modal.scss";
import { ReactNode } from "react";

interface UserInfoModalProps {
  record?: {
    name?: string;
    role?: string;
    message?: string;
  };
  children: ReactNode;
}

export const UserInfoModal = ({ record, children }: UserInfoModalProps) => {
  const mockRecord = {
    name: "Джон Доу",
    role: "Менеджер проектов",
    message: "johndoe@example.com",
  };

  const finalRecord = record || mockRecord;

  const randomImage = `https://picsum.photos/seed/${Math.random()}/50`;

  return (
    <Dropdown
      overlay={
        <div className="user-info-dropdown">
          <img
            src={randomImage}
            alt="User avatar"
            className="user-info-avatar"
          />
          <div className="user-info-details">
            <div className="user-info-name">{finalRecord.name}</div>
            <div className="user-info-organization">ООО "Пример"</div>
            <div className="user-info-role">{finalRecord.role}</div>
            <div className="user-info-email">
              <a
                href={`mailto:${finalRecord.message}`}
                className="user-info-email-link"
              >
                {finalRecord.message}
              </a>
            </div>
            <div className="user-info-phone">+7 (999) 123-45-67</div>
          </div>
        </div>
      }
      trigger={["click"]}
    >
      <Tooltip title="Краткое наименование организации">{children}</Tooltip>
    </Dropdown>
  );
};

