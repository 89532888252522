import { buildsServiceApi } from "@shared/api";

export interface RemoveProjectParticipantsRequest {
  projectParticipantId: string;
}

export async function removeProjectParticipant({
  projectParticipantId,
}: RemoveProjectParticipantsRequest): Promise<void> {
  const res = await buildsServiceApi.delete<void>(
    `/project-participants/${projectParticipantId}`,
  );
  return res.data;
}
