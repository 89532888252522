import { Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-objects-view.scss";
import { Breadcrumbs } from "@/features/breadcrumb";
import { ObjectTabs } from "@/features/object-tabs";
import { routes } from "@/shared/lib/react-router";
import { useObject } from "@entities/object/";
import { projectQueries } from "@entities/project/api/project.queries.ts";
import { useCoordinator } from "@shared/lib/hooks";
import { NotFoundFallback } from "@shared/ui";
import { ProjectObjectsSkeleton } from "./project-objects-skeleton";
import { useSuspenseQuery } from "@tanstack/react-query";

const { Title } = Typography;
const generateBreadcrumbs = (projectId: string, projectShortName: string) => {
  return [
    { path: routes.projects(), label: "Проекты" },
    {
      path: routes.project.root({ projectId }),
      label: projectShortName,
    },
    { path: routes.objects.root(projectId), label: "Объекты" },
  ];
};
export const ProjectObjectsView = () => {
  const { objectId, projectId } = useCoordinator() as {
    objectId: string;
    projectId: string;
  };
  const { data: object, isLoading, isError } = useObject({ objectId });
  const { data: project } = useSuspenseQuery(
    projectQueries.getProject({ projectId }),
  );

  if (isError) {
    return (
      <NotFoundFallback
        style={{
          width: "100%",
          height: "calc(100vh-104px)",
          justifyContent: "center",
          display: "flex",
        }}
      />
    );
  }

  if (isLoading) return <ProjectObjectsSkeleton />;
  return (
    <div className="general-view-container">
      <Breadcrumbs
        routes={generateBreadcrumbs(project.projectId, project.shortName)}
      />
      <Title level={3}>{object.shortName}</Title>
      <ObjectTabs />
      <Outlet />
    </div>
  );
};
