import { GetProjectParticipantsRequest } from "@entities/project";
import { LegacyApi, ModernApi } from "@shared/api/project";

export class ProjectQueriesTags {
  static root() {
    return ["project"] as const;
  }

  static legacyRoot() {
    return [...ProjectQueriesTags.root(), "legacy"] as const;
  }

  static createProject() {
    return [...ProjectQueriesTags.root(), "createProject"] as const;
  }

  static getProject(params: { projectId: string }) {
    return [...ProjectQueriesTags.root(), params.projectId] as const;
  }

  static getAllProjects(params: ModernApi.GetProjectsRequest) {
    return [...ProjectQueriesTags.root(), "getAllProjects", params] as const;
  }

  static getAllProjectsLegacy(params: LegacyApi.GetProjectsRequest) {
    return [
      ...ProjectQueriesTags.legacyRoot(),
      "getAllProjects",
      params,
    ] as const;
  }

  // Несколько проектов могут использовать один и тот же файл. Параметр projectId нужен для устранения коллизии.
  static getProjectImage(
    params: ModernApi.GetProjectImageRequest & { projectId: string },
  ) {
    return [...ProjectQueriesTags.root(), "getProjectImage", params] as const;
  }

  static login() {
    return [...ProjectQueriesTags.root(), "login"] as const;
  }

  static getProjectAdmins(params: { projectId: string }) {
    return [...ProjectQueriesTags.root(), "admins", params.projectId] as const;
  }

  static getProjectSettings(params: { projectId: string }) {
    return [
      ...ProjectQueriesTags.root(),
      "settings",
      params.projectId,
    ] as const;
  }

  // TODO: ДУМАЕМ О ПЕРЕНОСЕ!!
  static getProjectParticipants({
    projectId,
    ...params
  }: GetProjectParticipantsRequest) {
    return [
      ...ProjectQueriesTags.root(),
      "getProjectParticipants",
      projectId,
      params,
    ] as const;
  }
}
