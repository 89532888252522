import { Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-general-view.scss";
import { ProjectTabs } from "@/features/project-tabs";
import { projectQueries } from "@entities/project";
import { useCoordinator } from "@shared/lib/hooks";
import { useSuspenseQuery } from "@tanstack/react-query";
import { routes } from "@/shared/lib/react-router";
import { Breadcrumbs } from "@/features/breadcrumb";

const { Title } = Typography;

const generateBreadcrumbs = (projectId: string, projectShortName: string) => {
  return [
    { path: routes.projects(), label: "Проекты" },
    {
      path: routes.project.root({ projectId }),
      label: projectShortName,
    },
  ];
};

export const ProjectGeneralView = () => {
  const { objectId } = useCoordinator() as {
    objectId: string;
  };
  const { projectId } = useCoordinator() as {
    projectId: string;
  };
  const { data: project } = useSuspenseQuery(
    projectQueries.getProject({ projectId }),
  );

  return (
    <div className="general-view-container">
      <Breadcrumbs
        routes={generateBreadcrumbs(project.projectId, project.shortName)}
      />
      <Title level={3} style={{ marginBottom: "32px" }}>
        {project.shortName}
      </Title>
      <ProjectTabs />
      <Outlet />
    </div>
  );
};
