import { Flex } from "antd";
import SkeletonNode from "antd/es/skeleton/Node";

const LoadingComponent = () => {
  return (
    <Flex justify="center" vertical gap={8}>
      <SkeletonNode active style={{ width: "672px", height: "20px" }} />
      <SkeletonNode active style={{ width: "672px", height: "20px" }} />
      <SkeletonNode active style={{ width: "672px", height: "20px" }} />
    </Flex>
  );
};

export default LoadingComponent;
