import { Modal, Button, Typography, Divider, Input } from "antd";

const { Title, Text } = Typography;

interface ModalInfoProps {
  visible: boolean;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  Info?: string;
  inputValue?: string;
  onChangeValue?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  inputPlaceholder?: string;
  isInputModal?: boolean;
}

export const ModalInfo: React.FC<ModalInfoProps> = ({
  visible,
  confirmText = "Отправить",
  cancelText = "Отменить",
  onConfirm,
  onCancel,
  title,
  Info,
  inputPlaceholder,
  inputValue,
  onChangeValue,
  isInputModal,
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      // width={400}
      centered
      closeIcon={<span style={{ fontSize: "16px", color: "#BFBFBF" }}>×</span>}
      title={title}
    >
      <div>
        <Text>{Info}</Text>
        {isInputModal ? (
          <div>
            <div style={{ marginBottom: "4px" }}>{inputPlaceholder}</div>
            <Input.TextArea
              value={inputValue}
              onChange={onChangeValue}
            ></Input.TextArea>
          </div>
        ) : null}
        <Divider />
        <div
          style={{
            marginTop: "24px",
            display: "flex",
          }}
        >
          {onConfirm ? (
            <Button type="primary" onClick={onConfirm}>
              {confirmText}
            </Button>
          ) : null}
          {onCancel ? <Button onClick={onCancel}>{cancelText}</Button> : null}
        </div>
      </div>
    </Modal>
  );
};

