import React, { useId } from "react";
import "./project-avatar.scss";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Image, message } from "antd";
import { isAxiosError } from "axios";
import { projectQueries } from "@entities/project";
import { useProjectImage } from "@entities/project/queries/use-project-image.ts";
import { ErrorResponse } from "@shared/api/types";
import AvatarFallback from "@shared/assets/png/avatar-fallback.png";
import { PenIcon } from "@shared/assets/svg/icons";
import { Can } from "@shared/lib/casl";
import { useUpdateProjectAvatar } from "../../model";
import { getErrorMessage } from "@shared/api/utils";

interface ProjectAvatarProps {
  projectId: string;
}

export default function ProjectAvatar({ projectId }: ProjectAvatarProps) {
  const id = useId();

  const { mutateAsync } = useUpdateProjectAvatar();

  const { data: project } = useSuspenseQuery(
    projectQueries.getProject({ projectId }),
  );

  const { data: image } = useProjectImage({
    projectId,
    imageId: project.projectAvatarId ?? "",
    enabled: !!project.projectAvatarId,
  });

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    e.target.value = "";
    if (file) {
      message.open({
        key: "loading",
        type: "loading",
        content: "Загружаем...",
      });
      try {
        await mutateAsync({ file, projectId });
        message.open({
          key: "loading",
          type: "success",
          content: "Готово!",
        });
      } catch (error) {
        const msg = getErrorMessage(error);
        message.open({
          key: "loading",
          type: "error",
          content: msg,
        });
      }
    }
  };

  return (
    <div className="project-avatar">
      <Image
        className="project-avatar-img"
        src={image?.content}
        alt="Загородный дом"
        preview={{
          maskClassName: "project-avatar-mask",
        }}
        fallback={AvatarFallback}
        height="214px"
      />
      <Can I="edit" a="project">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={id}
          aria-label="Загрузить аватар"
          className="project-avatar-change-btn"
        >
          <PenIcon className="project-avatar-change-icon" />
        </label>
        <input
          onChange={onChange}
          id={id}
          className="project-avatar-change-input"
          type="file"
          accept="image/jpeg, image/png, image/jpg"
        />
      </Can>
    </div>
  );
}
