import { CompanyInfo } from "@entities/company";
import { buildsServiceApi } from "@shared/api";
import { Paginated } from "@shared/types";

export interface GetProjectParticipantsRequest {
  page?: number;
  limit?: number;
  search?: string;
  projectId: string;
}

export interface ProjectParticipantInfo {
  projectParticipantId: string;
  updateDate: string;
  updateUserId: string;
  company: CompanyInfo;
}

export interface GetProjectParticipantsResponse extends Paginated {
  projectId: string;
  participants: Array<ProjectParticipantInfo>;
}

export async function getProjectParticipants({
  projectId,
  ...params
}: GetProjectParticipantsRequest): Promise<GetProjectParticipantsResponse> {
  const res = await buildsServiceApi.get<GetProjectParticipantsResponse>(
    `/projects/${projectId}/participants`,
    { params },
  );
  return res.data;
}
