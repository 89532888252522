import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { Outlet } from "react-router-dom";
import { PrivateCheck } from "@app/composition/layouts/user/ui/private/private-check.tsx";
import { userQueries } from "@entities/user";
import { queryClient } from "@shared/lib/react-query";
import { Footer } from "./footer";
import { UserHeader } from "./header/header";

export const UserLayout = () => {
  // const { isOpen } = useModalStore();

  const [messageApi, contextHolder] = message.useMessage();

  return (
    <PrivateCheck>
      <div style={{ minHeight: "calc(100vh - 104px)" }}>
        {/* <Splitter.Panel */}
        {/*  style={{ padding: "0px 0px" }} */}
        {/*  defaultSize="85%" */}
        {/*  min="70%" */}
        {/*  max="100%" */}
        {/* > */}
        {contextHolder}
        <UserHeader />
        <main
          style={{
            minHeight: "calc(100vh - 104px)",
            display: "flex",
            position: "relative",
          }}
        >
          <Outlet />
        </main>
        <Footer />
        {/* </Splitter.Panel> */}
        {/* {isOpen && ( */}
        {/*  <Splitter.Panel min="15%" max="30%" resizable={isOpen}> */}
        {/* <Notifications /> */}
        {/*  </Splitter.Panel> */}
        {/* )} */}
      </div>
    </PrivateCheck>
  );
};
