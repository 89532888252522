import { MainJournal } from "@/shared/api/journal";

export function prepareResponsiblePersonsData(
  mainJournal: MainJournal | null | undefined,
) {
  const representatives: Array<{
    key: string;
    name: string;
    role: string;
    position: string;
    email: string;
    phone: string;
  }> = [];

  if (!mainJournal?.titleJournal) {
    console.warn("Данные mainJournal или titleJournal отсутствуют");
    return representatives;
  }

  mainJournal

  const addRepresentatives = (
    reps: //@ts-ignore
    | MainJournal["titleJournal"]["developer"]["representatives"]
      | null
      | undefined,
    role: string,
  ) => {
    if (!Array.isArray(reps)) {
      console.warn(
        `Представители для роли "${role}" отсутствуют или некорректны`,
      );
      return;
    }

    reps.forEach((rep) => {
      if (!rep?.user) {
        console.warn(`Некорректный представитель для роли "${role}":`, rep);
        return;
      }

      representatives.push({
        key: `${role}-${rep.representativeId || "unknown"}`,
        name: `${rep.user.surname || "Не указано"} ${rep.user.firstName || "Не указано"} ${
          rep.user.middleName || ""
        }`.trim(),
        role,
        position: rep.user.specialistNumberBuilding || "Не указано",
        email: rep.user.userEmail || "Не указано",
        phone: rep.user.mobilePhone || "Не указано",
      });
    });
  };

  addRepresentatives(
    mainJournal.titleJournal.developer?.representatives,
    "Застройщик",
  );
  addRepresentatives(
    mainJournal.titleJournal.technicalCustomer?.representatives,
    "Технический заказчик",
  );
  addRepresentatives(
    mainJournal.titleJournal.buildingContractor?.representatives,
    "Подрядчик",
  );
  addRepresentatives(
    mainJournal.titleJournal.designerSupervisions?.flatMap(
      (sup) => sup.representatives,
    ),
    "Авторский надзор",
  );
  addRepresentatives(
    mainJournal.titleJournal.otherDevelopers?.flatMap(
      (dev) => dev.representatives,
    ),
    "Другой разработчик",
  );

  return representatives;
}

