import { Row, Col, Button, Skeleton, Typography } from "antd";
import { SettingsCardItem } from "../settings-card-item/settings-card-item";
import { ProjecSettingApi } from "@/shared/api/project/types";
import { Can } from "@/shared/lib/casl";
import { SettingsSkeletonList } from "../settings-sceleton-list";
interface ISettingsCardBlock {
  blockTitle: string;
  settingsList?: ProjecSettingApi.IntegrationSetting[];
  isLoading?: boolean;
  isAdd?: string;
}

export const SettingsCardBlock = ({
  blockTitle,
  settingsList,
  isLoading,
  isAdd,
}: ISettingsCardBlock) => {
  return (
    <>
      {isLoading ? (
        <SettingsSkeletonList />
      ) : (
        <>
          {settingsList?.length && settingsList.length > 0 ? (
            <div className="project-setting-container">
              <div className="project-setting-header">
                <h2 className="project-setting-title">{blockTitle}</h2>
                {isAdd ? (
                  <Can I="access" a="project">
                    <Button type="link">Добавить</Button>
                  </Can>
                ) : null}
              </div>
              <Row gutter={[16, 16]}>
                {settingsList.map((card) => (
                  <Col
                    key={card.integrationId}
                    xs={24}
                    sm={12}
                    md={8}
                    className="project-setting-card-col"
                  >
                    <SettingsCardItem settingsInfo={card} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Typography.Title level={3} style={{ color: "#888" }}>
              Нет данных
            </Typography.Title>
          )}
        </>
      )}
    </>
  );
};
