import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProjectQueriesTags } from "@shared/api/project";
import { removeProjectParticipant } from "../api/remove-participant";

export function useRemoveProjectParticipant(projectId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectParticipantId }: { projectParticipantId: string }) =>
      removeProjectParticipant({ projectParticipantId }),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ProjectQueriesTags.getProjectParticipants({
            projectId: projectId ?? "",
          }),
        })
        .then();
    },
  });
}
