import { queryOptions } from "@tanstack/react-query";
import { ProjecSettingApi } from "@/shared/api/project/types";
import { getProjectParticipants } from "./get-participants";
import { getProject, GetProjectRequest } from "./get-project";
import { getProjectAdmin, GetProjectAdminRequest } from "./get-project-admin";
import { getProjectSettings } from "./get-project-settings";
import { GetProjectParticipantsRequest } from ".";

export const projectQueries = {
  rootKey: () => ["project"],

  getProject: ({ projectId, idType }: GetProjectRequest) =>
    queryOptions({
      queryKey: [...projectQueries.rootKey(), projectId, "getProject"],
      queryFn: () => getProject({ projectId, idType }),
    }),

  getProjectAdmin: ({ projectId }: GetProjectAdminRequest) =>
    queryOptions({
      queryKey: [...projectQueries.rootKey(), projectId, "getProjectAdmin"],
      queryFn: () => getProjectAdmin({ projectId }),
    }),

  getProjectParticipants: ({
    projectId,
    ...rest
  }: GetProjectParticipantsRequest) =>
    queryOptions({
      queryKey: [
        ...projectQueries.rootKey(),
        projectId,
        "getProjectParticipants",
        rest,
      ],
      queryFn: () => getProjectParticipants({ projectId, ...rest }),
    }),

  getProjectSettings: ({
    projectId,
  }: ProjecSettingApi.ProjectSettingsRequest) =>
    queryOptions({
      queryKey: [...projectQueries.rootKey(), "getProjectSettings", projectId],
      queryFn: () => getProjectSettings({ projectId }),
    }),
};
