import { useEffect, useState } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { DevTool } from "@hookform/devtools";
import { Input, DatePicker, Button, Form } from "antd";
import { useForm, Controller } from "react-hook-form";
import { FormItem } from "react-hook-form-antd";
import { useObject } from "@entities/object";
import {
  ObjectFormDate,
  ObjectFormInput,
  ObjectFormInputDate,
  ObjectFormRadio,
} from "..";
import "./object-form-create.scss";
import { ObjectFormLoadFile } from "../object-form-load-file/object-form-load-file";
import { ObjectModalAddress } from "../object-modal-add-address/object-modal-add-address";
import { DocumentSelector } from "@/shared/ui/document-selector";

type FormType = {
  shortName: string;
  fullName: string;
  descriptionProjects: string;
  expertiseDate: string;
  expertiseNumber: string;
  isTemporary: string;
  isTemporaryExpertise: string;
  issueDate: string;
  objectDescription: string;
  objectName: string;
  objectNameShort: string;
  permitNumber: string;
  test: string;
  constructionStart: string;
  constructionEnd: string;
  descriptionStage: string;
};

interface IObjectFormCreate {
  handleIsForm: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    status: boolean,
  ) => void;
  objectId: null | string;
}

export const ObjectFormCreate = ({
  handleIsForm,
  objectId,
}: IObjectFormCreate) => {
  const { data, isLoading } = useObject({
    objectId: objectId || "",
    enabled: Boolean(objectId),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>();
  useEffect(() => {
    if (data) {
      console.log("213123123");
      setValue("shortName", data.fullName);
      setValue("objectNameShort", data.shortName);
    }
  }, [data]);
  const [isModalAddress, setIsModalAddress] = useState(false);
  const handleSetIsModalAddress = () => {
    setIsModalAddress(true);
  };
  const onSubmit = (data: FormType) => {
    console.log(data);
  };

  const [loadDocuments, setLoadDocuments] = useState<{
    [key: string]: { title: string; status: boolean; id: number };
  }>({
    firstDocument: {
      title: "Прикрепить документ",
      status: false,
      id: 1,
    },
    secondElement: {
      title: "Прикрепить подпись",
      status: false,
      id: 2,
    },
  });

  const toggleDocumentStatus = (key: string) => {
    setLoadDocuments((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        status: !prev[key].status,
      },
    }));
  };
  return (
    <div className="project-object-wrapper">
      <DevTool control={control} />
      <div className="project-object-container">
        <Form onFinish={handleSubmit(onSubmit)} className="custom-form">
          <div className="form-section">
            <FormItem
              label="Краткое наименование обьекта"
              layout="vertical"
              control={control}
              name="shortName"
            >
              <Input
                disabled={isLoading}
                placeholder="Краткое наименование обьекта"
              />
            </FormItem>
            <FormItem
              label="Наименование объекта капитального строительства в соответствии с проектной документацией"
              layout="vertical"
              control={control}
              name="fullName"
            >
              <Input
                disabled={isLoading}
                placeholder="Жилой двухэтажный каркасный дом синего цвета с блестящей трубой снаружи"
              />
            </FormItem>

            <FormItem
              label="Краткие проектные характеристики"
              layout="vertical"
              control={control}
              name="descriptionProjects"
            >
              <Input disabled={isLoading} placeholder="Удовлетворительные" />
            </FormItem>

            <FormItem
              label="Описание этапа строительства, реконструкции (необ.)"
              layout="vertical"
              control={control}
              name="descriptionStage"
            >
              <Input
                disabled={isLoading}
                placeholder="Бесконечное строительство"
              />
            </FormItem>

            <ObjectFormRadio
              name="constructionType"
              label="Вид строительства"
              control={control}
              radioOptions={[
                "Строительство",
                "Реконструкция",
                "Капитальный ремонт",
              ]}
            />
          </div>

          <div className="form-section address">
            <h3>Адрес</h3>
            <div className="form-address-button">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleSetIsModalAddress}
              >
                Добавить адрес
              </Button>
            </div>

            {/* <div className="object-form-error">Адрес должен быть добавлен</div> */}
          </div>

          <div className="form-section">
            <h3>Разрешение на строительство</h3>
            <ObjectFormRadio
              name="test"
              control={control}
              radioOptions={["Требуется", "Не требуется"]}
            />
            <div>
              Заполняется если разрешение на строительство требуется в
              соответствии со ст. 51 Градостроительного кодекса РФ
            </div>
            <ObjectFormInputDate
              nameFirst="permitNumber"
              nameSecond="issueDate"
              control={control}
              labelFirst=""
              labelSecond=""
              placeholderFirst="№ разрешения"
              placeholderSecond="Дата выдачи"
              errorMessage={errors?.permitNumber?.message}
            />
            <ObjectFormInput
              name="test"
              control={control}
              label="Наименование органа, выдавшего разрешение"
              placeholder=""
              errorMessage={errors.test?.message}
            />
            <ObjectFormDate
              nameFirst="test"
              nameSecond="test"
              control={control}
              labelFirst=""
              labelSecond=""
              placeholderFirst="Срок действия (необязательно)"
              placeholderSecond="Продлен до (необязательно)"
              errorMessage={errors?.test?.message}
            />
            <DocumentSelector
              loadDocuments={loadDocuments}
              toggleDocumentStatus={toggleDocumentStatus}
              style={{ marginTop: "16px" }}
            />
            <div className="dottedLine" />
            <Button
              type="dashed"
              style={{
                width: "auto",
                marginTop: "24px",
                backgroundColor: "#EAEEF8",
              }}
            >
              Добавить еще разрешение на строительство
            </Button>
          </div>

          <div className="form-section">
            <h3>
              Сведения о положительном заключении экспертизы проектной
              документации
            </h3>
            <ObjectFormRadio
              name="test"
              control={control}
              radioOptions={["Требуется", "Не требуется"]}
            />
            <div>
              Заполняется если в соответствии со ст.49 Градостроительного
              кодекса РФ проводится экспертиза проектной документации
            </div>
            <div className="field-group">
              <Controller
                name="expertiseNumber"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="№ заключения" />
                )}
              />
              <Controller
                name="expertiseDate"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Дата выдачи" />
                )}
              />
            </div>
          </div>

          <div className="form-section">
            <h3>Сроки строительства</h3>
            <div className="field-group">
              <Controller
                name="constructionStart"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Начало" />
                )}
              />
              <Controller
                name="constructionEnd"
                control={control}
                render={({ field }) => (
                  <DatePicker {...field} placeholder="Окончание" />
                )}
              />
            </div>
          </div>
          <div className="object-button-container">
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </div>
        </Form>
        <CloseOutlined
          className="object-form-icon-close"
          onClick={(event) => handleIsForm(event, false)}
        />
      </div>
      <ObjectModalAddress
        isModalAddress={isModalAddress}
        setIsModalAddres={setIsModalAddress}
      />
    </div>
  );
};
