import { z } from "zod";

export const JournalRequestSchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  buildingObjectId: z.string().uuid("Неверный формат UUID"),
});

export const JournalResponseSchema = z.object({
  total: z.number(),
  page: z.number(),
  limit: z.number(),
  buildingObject: z.object({
    buildingObjectId: z.string(),
    projectId: z.string(),
    shortName: z.string(),
    fullName: z.string(),
    descriptionProjects: z.string(),
    descriptionStage: z.string(),
    constructionType: z.string(),
    buildingStart: z.string(),
    buildingEnd: z.string(),
    address: z.object({
      addressId: z.string(),
      addressType: z.string(),
      addressString: z.string(),
      countryId: z.number(),
      regionId: z.number(),
      districtName: z.string(),
      districtCode: z.string(),
    }),
    mainJournals: z.array(
      z.object({
        mainJournalId: z.string(),
        status: z.string(),
        createDate: z.string(),
        updateDate: z.string(),
        titleJournal: z.object({
          titleId: z.string(),
          mainJournalNumber: z.string(),
          noVersionId: z.string(),
          noVersionHistoryId: z.string(),
          status: z.string(),
        }),
      }),
    ),
  }),
});

export type JournalRequest = z.infer<typeof JournalRequestSchema>;
export type JournalResponse = z.infer<typeof JournalResponseSchema>;
