import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { NotFoundPage } from "@pages/http-errors";
import { routes } from "@shared/lib/react-router";

export const NotFoundPageRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.page_not_found()}`,
    element: createElement(NotFoundPage),
    children: [...rest],
  };
};
