import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@shared/lib/react-query";
import {
  deleteJournal,
  DeleteJournalRequest,
} from "@entities/journal/api/journals.api";
import { journalsQueries } from "@entities/journal/api/journals.queries";

export function useDeleteJournal({
  buildingObjectId,
  page,
  limit,
}: {
  buildingObjectId: string;
  page: number;
  limit: number;
}) {
  return useMutation<void, Error, DeleteJournalRequest>({
    mutationFn: deleteJournal,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: journalsQueries.getDraftJournals({
          buildingObjectId,
          page,
          limit,
        }).queryKey,
      });
    },

    onError: (error) => {
      console.error("Ошибка удаления журнала:", error);
    },
  });
}

