import { SettingsCardBlock } from "@/features/settings-card-block/ui/settings-card-block";
import "./object-settings.scss";
import { objectQueries } from "@/entities/object/api/object.queries";
import { useCoordinator } from "@/shared/lib/hooks";
import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";

const cardsIntegrationMock = [
  {
    id: 1,
    title: "Дедлайны",
    description:
      "Позволяет настраивать срок устранения и реакции на события. Применяется только для выбранного объекта",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
    isActive: true,
  },
  {
    id: 2,
    description:
      "Отображает настройки указанные в проекте Изменение настроек происходит в проекте",
    title: "EXON",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
];

export const ObjectSettings = () => {
  // const [api, contextHolder] = notification.useNotification();
  const { objectId } = useCoordinator();

  const { data, isLoading, error } = useQuery(
    objectQueries.getObjectSettings({ objectId }),
  );

  if (isLoading) {
    console.log("isLoading");
  }

  if (error) {
    console.log(error);
    notification.error({
      message: "Не можем отобразить настройки обьекта",
      description: ``,
      placement: "topRight",
      showProgress: false,
    });
  }

  return (
    <>
      {/* {contextHolder} */}
      <SettingsCardBlock
        blockTitle="Дополнения "
        // cards={cardsIntegrationMock}
      />
    </>
  );
};
