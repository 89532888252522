import { AxiosResponse } from "axios";
import { buildsServiceApi, oldBaseApi, s3AdapterApi } from "@shared/api";
import { LegacyApi, ModernApi } from "@shared/api/project";
import {
  CreateProjectDto,
  CreateProjectResponse,
  GetProjectAdminResopnse,
  ProjecSettingApi,
} from "@shared/api/project/types.ts";
import { ProjectSettingsRequestSchema } from "./contracts";

export class ProjectService {
  static createProject(data: {
    CreateProjectDto: CreateProjectDto;
  }): Promise<AxiosResponse<CreateProjectResponse>> {
    return oldBaseApi.post<CreateProjectResponse>("projects", data);
  }

  static async getAllProjectLegacy(
    params: LegacyApi.GetProjectsRequest,
  ): Promise<LegacyApi.GetProjectsResponse> {
    const res = await oldBaseApi.get<LegacyApi.GetProjectsResponse>(
      "api/projects/",
      {
        params,
      },
    );
    return res.data;
  }

  static async getAllProjects(
    params: ModernApi.GetProjectsRequest,
  ): Promise<ModernApi.GetProjectsResponse> {
    const res = await buildsServiceApi.get<ModernApi.GetProjectsResponse>(
      "/projects",
      {
        params,
        headers: {
          Accept: "*/*",
        },
      },
    );
    return res.data;
  }

  static async getProjectImage(
    params: ModernApi.GetProjectImageRequest,
  ): Promise<ModernApi.GetProjectImageResponse> {
    const res = await s3AdapterApi.get(`/files/${params.fileId}`, {
      params: {
        type: params.type,
      },
    });
    return res.data;
  }

  static async addProjectAdmin(projectId: string, userId: string) {
    return buildsServiceApi.post(`/projects/${projectId}/admin`, { userId });
  }

  static async removeProjectAdmin(projectId: string, userId: string) {
    return buildsServiceApi.delete(`/projects/${projectId}/admin`, {
      data: { userId },
    });
  }

 
}
