import { useMutation } from "@tanstack/react-query";
import { ProjectService } from "@shared/api/project";

export function useAddProjectAdmin(projectId: string) {
  return useMutation({
    mutationFn: (userId: string) =>
      ProjectService.addProjectAdmin(projectId, userId),
  });
}

export function useRemoveProjectAdmin(projectId: string) {
  return useMutation({
    mutationFn: (userId: string) =>
      ProjectService.removeProjectAdmin(projectId, userId),
  });
}
