import { useQuery } from "@tanstack/react-query";
import { ObjectQueriesTags, ObjectService } from "@shared/api/object";
import { GetObjectResponse } from "@shared/api/object/types.ts";

interface IUseGetObject {
  objectId: string;
  enabled?: boolean;
}

export function useObject({ objectId, enabled = true }: IUseGetObject) {
  const query = useQuery({
    queryFn: () => ObjectService.getObject({ objectId }),
    queryKey: ObjectQueriesTags.getObject({ objectId }),
    enabled,
  });
  return {
    ...query,
    data: query.data as GetObjectResponse,
  };
}
