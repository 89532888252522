import { Fragment, useState } from "react";
import { Typography, Button, Menu, Col, Dropdown, Row } from "antd";
import { RepresentationMoodal } from "@/features/representation-modal";
import {
  BasketIcon,
  MoreOneIcon,
  SuccessIcon,
  EditIcon,
} from "@/shared/assets/svg/icons";
import PdfIcon from "@/shared/assets/svg/icons/pdf-icon.png";
import SigIcon from "@/shared/assets/svg/icons/sig-icon.png";
import { useDisclosure } from "@/shared/lib/hooks";
import { Link } from "react-router-dom";
import { signStatusEnum } from "@/app/composition/views/project-journals/ui/project-journals";
import { JournalsSigeInfo } from "../journals-sige-info/journals-sige-info";

const tableMock = [
  {
    id: 1,
    title: "Организация",
    info: "ООО “Технические решения в строительстве”",
  },
  {
    id: 2,
    title: "Должность",
    info: "Дизайнер",
  },
  {
    id: 3,
    title: "Документ, №, дата",
    info: "Приказ 56-дзн, 01.07.2022",
    isCopy: true,
  },
  {
    id: 4,
    title: "Почта",
    info: "dkharin@builddocs.ru",
    isEmail: true,
  },
  {
    id: 5,
    title: "Номер в НОПРИЗ",
    info: "-",
  },
  {
    id: 6,
    title: "Номер в НОСТРОЙ",
    info: "-",
  },
  {
    id: 7,
    title: "Телефон",
    info: "8 (921) 334-4321",
  },
];

type EmployeeProps = {
  isRepresented?: boolean;
  signStatus: signStatusEnum;
  setSignStatus: React.Dispatch<React.SetStateAction<signStatusEnum>>;
};

export const JournalsCommissionerTable: React.FC<EmployeeProps> = ({
  isRepresented = false,
  signStatus,
  setSignStatus,
}) => {
  console.log(isRepresented);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employees, setEmployees] = useState<
    { id: number; isRepresented: boolean }[]
  >([]);

  const handleAdd = () => {
    setEmployees((prev) => [...prev, { id: prev.length + 1, isRepresented }]);
    onClose();
  };

  const handleDelete = (id: number) => {
    setEmployees((prev) => prev.filter((employee) => employee.id !== id));
  };

  const handleToggleRepresented = (id: number) => {
    setEmployees((prev) =>
      prev.map((employee) =>
        employee.id === id
          ? { ...employee, isRepresented: !employee.isRepresented }
          : employee,
      ),
    );
  };

  const menu = (id: number) => (
    <Menu>
      <Menu.Item key="edit" icon={<EditIcon />} onClick={onOpen}>
        Редактировать
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<BasketIcon />}
        onClick={() => handleDelete(id)}
      >
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container" id="journals-commissioner-table">
      <Typography.Title level={4}>
        Уполномоченный представитель технического заказчика
      </Typography.Title>
      {employees.map((employee) => (
        <div
          key={employee.id}
          style={{ marginBottom: "24px" }}
          className={` ${employee.isRepresented ? `table-border-line ${signStatus === signStatusEnum.signed ? "green" : ""}${signStatus === signStatusEnum.rejected ? "red" : ""}${signStatus === signStatusEnum.onSign ? "blue" : ""} table-container` : ""}`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title
              level={5}
              style={{ fontWeight: "400", marginTop: "24px" }}
            >
              Харин Дмитрий {employee.id}
            </Typography.Title>
            <Dropdown overlay={menu(employee.id)} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>

          <Row
            gutter={[16, 16]}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {tableMock.map(({ id, title, info, isCopy, isEmail }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {isEmail ? <Link to={info}>{info}</Link> : null}
                  {!isEmail && !isCopy ? <div>{info}</div> : null}
                  {isCopy ? (
                    <div className="flex-between">
                      <div>{info}</div>
                      <div className="flex-default">
                        <img
                          width={16}
                          height={16}
                          alt=""
                          src={PdfIcon}
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                        <img
                          width={16}
                          height={16}
                          alt=""
                          src={SigIcon}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Fragment>
            ))}
          </Row>
          {employee.isRepresented && (
            <JournalsSigeInfo
              setSignStatus={setSignStatus}
              signStatus={signStatus}
            />
          )}
        </div>
      ))}
      <Button type="primary" style={{ marginTop: "14px" }} onClick={onOpen}>
        Добавить сотрудника
      </Button>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAdd}
      />
    </div>
  );
};

