import { buildsServiceApi } from "@shared/api";

export interface AddProjectParticipantRequest {
  projectId: string;
  body: {
    companyId: string;
  };
}

export interface AddProjectParticipantResponse {
  projectParticipantId: string;
  projectId: string;
  companyId: string;
  updateDate: string;
  updateUserId: string;
}

export async function addParticipant({
  projectId,
  body,
}: AddProjectParticipantRequest): Promise<AddProjectParticipantResponse> {
  const res = await buildsServiceApi.post<AddProjectParticipantResponse>(
    `/projects/${projectId}/participants`,
    body,
  );
  return res.data;
}
