import React, { useMemo, useState } from "react";
import { type SelectProps } from "antd";
import { useDaDataSuggestion } from "../query";
import "./party-suggestion.scss";
import { DaDataParty } from "../types";
import { PartySelectOption, PartySelect } from ".";

interface PartySuggestionsProps extends SelectProps {
  onPartySelect: (party: DaDataParty) => void;
  itemCount?: number;
}

export function PartySuggestions({
  onPartySelect,
  itemCount = 5,
  ...rest
}: PartySuggestionsProps) {
  const [query, setQuery] = useState<string>("");

  const { data } = useDaDataSuggestion({
    query,
    count: itemCount,
  });

  // TODO: посмотреть, останется ли инпутлаг после сборки. Если останется - поменять на useEffect.
  // const [data, setData] = useState<GetDaDataSuggestResponse | null>(null);
  //
  // useEffect(() => {
  //   DaDataService.getDaDataSuggestParty({ query, count: 5 }).then((res) => setData(res));
  // }, [query]);

  const onSearch = (value: string) => {
    setQuery(value);
  };

  // antd Select не поддерживает объектные ключи, поэтому как уникальный идентификатор используем ОГРН
  // и ищем выбранный объект по нему.
  const onChange = (value: string) => {
    const selected = data?.suggestions.find((el) => el.data.ogrn === value);
    if (selected) {
      onPartySelect(selected.data);
    }
  };

  const options = useMemo(
    () =>
      data?.suggestions.map((val) => {
        return {
          value: val.data.ogrn,
          label: <PartySelectOption value={val.data} />,
          inputLabel: val.data.name.short,
          className: "party-suggestion",
        };
      }),
    [data?.suggestions],
  );

  return (
    <PartySelect
      onSelect={onChange}
      options={options}
      onSearch={onSearch}
      itemCount={itemCount}
      {...rest}
    />
  );
}
