import { companyServiceApi } from "@shared/api";
import {
  FindCompanyByInnKppRequest,
  FindCompanyByInnKppResponse,
} from "../contract";

export class CompanyService {
  static async findCompanyByInnKpp(
    params: FindCompanyByInnKppRequest,
  ): Promise<FindCompanyByInnKppResponse> {
    const res = await companyServiceApi.get<FindCompanyByInnKppResponse>(
      "companies",
      { params },
    );
    return res.data;
  }
}
