import "./base-error-page.scss";
import clsx from "clsx";

interface BaseErrorPageProps {
  title: string;
  description: React.ReactNode;
  asset: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function BaseErrorPage({
  title,
  description,
  asset,
  className,
  style,
}: BaseErrorPageProps) {
  return (
    <div className={clsx("base-error-page-wrapper", className)} style={style}>
      <div className="base-error-page">
        <h1 className="base-error-page-title">{title}</h1>
        <div className="base-error-page-container">
          <div className="base-error-page-asset">{asset}</div>
          <p className="base-error-page-description">{description}</p>
        </div>
      </div>
    </div>
  );
}
