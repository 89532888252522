import { AxiosResponse } from "axios";
import { oldBaseApi } from "@shared/api";

export class UserService {
  static oldService_refreshToken(refreshToken: string): Promise<
    AxiosResponse<{
      jwtToken: string;
      refreshToken: string;
      keycloak: {
        accessToken: string;
        expiresIn: number;
        refreshToken: string;
        tokenType: string;
      };
    }>
  > {
    return oldBaseApi.post(`api/tokens/jwt/refresh`, refreshToken);
  }
}
