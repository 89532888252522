import { buildsServiceApi } from "@shared/api";
import { Project } from "../types/project";

export type GetProjectRequest = {
  projectId: string;
  idType?: "projectId" | "externalId";
};
export type GetProjectResponse = Project;
export const getProject = ({
  projectId,
  idType,
}: GetProjectRequest): Promise<GetProjectResponse> => {
  return buildsServiceApi
    .get(`/projects/${projectId}`, {
      params: { idType: idType || "projectId" },
    })
    .then((r) => r.data);
};
