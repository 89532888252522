// TODO: дописать для всей ручки
import { buildsServiceApi } from "@shared/api";
import { Address, Project } from "../types";

interface UpdateProjectDto {
  shortName: string;
  fullName: string;
  descriptionProject: string;
  constructionType: "CONSTRUCTION" | "RENOVATION";
  buildingStart: string;
  buildingEnd: string;
  address: Address;
}

interface FileMetadata {
  fileNumber: number;
  fileName: string;
  fileType: string;
  fileContentLength: number;
}

export interface UpdateProjectRequest {
  projectId: string;
  updateProjectDto?: UpdateProjectDto;
  fileMetadata?: FileMetadata;
  file?: File;
}

export type UpdateProjectResponse = Project;

export async function updateProject({
  projectId,
  updateProjectDto,
  file,
  fileMetadata,
}: UpdateProjectRequest): Promise<UpdateProjectResponse> {
  const formData = new FormData();

  if (updateProjectDto) {
    const updateProjectRequestJson = JSON.stringify(updateProjectDto);
    formData.append(
      "updateProjectRequest",
      new Blob([updateProjectRequestJson], { type: "application/json" }),
    );
  }

  if (!!file && !!fileMetadata) {
    const fileMetadataJson = JSON.stringify(fileMetadata);
    formData.append(
      "fileMetadata",
      new Blob([fileMetadataJson], { type: "application/json" }),
    );
    formData.append("file", file, file.name);
  }

  const res = await buildsServiceApi.putForm<UpdateProjectResponse>(
    `/projects/${projectId}`,
    formData,
  );

  return res.data;
}
