import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@shared/lib/react-query";
import {
  createJournal,
  CreateJournalRequest,
  CreateJournalResponse,
} from "@entities/journal/api/journals.api";
import { journalsQueries } from "@entities/journal/api/journals.queries";

export function useCreateJournal({
  buildingObjectId,
  page,
  limit,
}: CreateJournalRequest & { page: number; limit: number }) {
  return useMutation<CreateJournalResponse, Error, CreateJournalRequest>({
    mutationFn: createJournal,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: journalsQueries.getDraftJournals({
          buildingObjectId,
          page,
          limit,
        }).queryKey,
      });
    },

    onError: (error) => {
      console.error("Ошибка создания журнала:", error);
    },
  });
}
