import { ExtractSubjectType, SubjectRawRule, SubjectType } from "@casl/ability";
import { Project } from "@entities/project";

export type Actions = "access" | "read" | "edit" | "delete" | string;

export type Subjects =
  | "company"
  | "project"
  | "object"
  | "acts_service"
  | "contract"
  | "journals_service"
  | "general_work_journal"
  | "act"
  | "title"
  | SubjectType;

export type SubjectRule = SubjectRawRule<
  string,
  ExtractSubjectType<Subjects>,
  unknown
>;

export type Entity = Project;

export const PermissionsMap = {
  ADMIN: ["access", "read", "edit", "delete"],
  ALL_USER: ["access", "read", "edit"],
  ACCESS: ["access"],
  READ: ["read"],
  EDIT: ["edit"],
  DELETE: ["delete"],
};
