import { useState } from "react";
import { CrossStorageClient } from "cross-storage";
import { env } from "@/shared/config";
import { TokenManipulate } from "../session-service";

const storage = new CrossStorageClient(env.OLD_FRONTEND_URL, {});

export const useAuth = ({ time = 3000 }: { time?: number }) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isProccesing, setIsProccesing] = useState(true);

  const navigateToOldDomain = () => {
    setTimeout(() => {
      window.location.href = `${env.OLD_FRONTEND_URL}/sign-in?redirectTo=${env.ACTUAL_FRONTEND}`;
      console.log("navigate to", `${env.OLD_FRONTEND_URL}/sign-in`);
    }, time);
  };

  storage
    .onConnect()
    .then(() => {
      return storage.get("token", "iat");
    })
    .then((tokens) => {
      if (tokens[0] === null || tokens[1] === null) throw new Error();
      const token = localStorage.getItem("token");

      console.log(tokens[0] !== token);
      if (token !== tokens[0]) {
        TokenManipulate.print({
          token: tokens[0],
          iat: tokens[1],
        });
        localStorage.removeItem("newToken");
        localStorage.removeItem("newIat");
      }

      setIsAuth(true);
    })
    .catch(() => {
      localStorage.clear();
      setIsAuth(false);
      navigateToOldDomain();
    })
    .finally(() => {
      setIsProccesing(false);
    });
  return {
    isAuth,
    isProccesing,
  };
};
