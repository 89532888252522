import { Link } from "react-router-dom";
import { Dropdown, Menu, notification, Skeleton } from "antd";
import { BasketIcon, CopyIcon, MoreOneIcon } from "@/shared/assets/svg/icons";
import "./object-journals-element.scss";
import {
  JournalsStatuses,
  MainJournal,
} from "@/entities/journal";
import { ObjectJournalsListSkeleton } from "../object-journals-list-skeleton";
import { routes } from "@/shared/lib/react-router";
import { useCoordinator } from "@/shared/lib/hooks";
import { useDeleteJournal } from "../../model/delete-journal.mutation";

interface IObjectJournalsElement {
  journalInfo: MainJournal;
  index?: number;
}

export const ObjectJournalsElement = ({
  journalInfo,
  index,
}: IObjectJournalsElement) => {
  const [api, contextHolder] = notification.useNotification();

  const { projectId, objectId } = useCoordinator() as {
    projectId: string;
    objectId: string;
  };

  const { mutate, isPending } = useDeleteJournal({
    buildingObjectId: objectId,
    page: 1,
    limit: 10,
  });

  const handleDeleteJournal = () => {
    mutate(
      { mainJournalId: journalInfo.mainJournalId },
      {
        onSuccess: () => {
          api.success({
            message: "Успешно",
            description: "Удалили журнал",
            placement: "topRight",
            showProgress: false,
          });
        },
        onError: () => {
          api.error({
            message: "Успешно",
            description: "Невозможно удалить",
            placement: "topRight",
            showProgress: false,
          });
        },
      },
    );
  };
  const handleCopy = () => {
    console.log(`Скопировать: ${journalInfo.mainJournalId}`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="copy" icon={<CopyIcon />} onClick={handleCopy}>
        Скопировать
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<BasketIcon />}
        onClick={handleDeleteJournal}
      >
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container journal-container">
      {contextHolder}
      <div className="journal-header-container">
        <div className="journal-info-container">
          <Link
            to={routes.journals.title(projectId)}
            style={{ marginRight: "16px", fontSize: "16px" }}
          >
            {"Общий журнал работ №" + (Number(index) + 1)}
          </Link>
          <div className="journal-tags-container">
            <div className={`journal-tag ${journalInfo.status.toLowerCase()}`}>
              {JournalsStatuses[journalInfo.titleJournal.status]}
            </div>
          </div>
        </div>

        <div className="journal-date-container">
          <div className="journal-date-info">
            {new Date(journalInfo.createDate).toLocaleDateString()}
          </div>
          <div className="journal-date-time">
            {new Date(journalInfo.createDate).toLocaleTimeString("ru-RU", {
              timeZone: "Europe/Moscow",
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            МСК
          </div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="journal-description">
        Ленинградская область, Кировский район, дер. Горная Шальдиха, ул.
        Хвойная уч. 40
      </div>
    </div>
  );
};
