import { Button, notification, Typography } from "antd";
import { ObjectJournalsList } from "../object-journals-list";
import { useCoordinator } from "@/shared/lib/hooks";
import { useCreateJournal } from "../../model/create-journal.mutation";
import { ObjectJournalsSkeleton } from "../object-journals-skeleton";

const { Title } = Typography;

export const ObjectJournals = () => {
  const { objectId } = useCoordinator() as { objectId: string };
  // const [api, contextHolder] = notification.useNotification();

  const { mutate, isPending } = useCreateJournal({
    buildingObjectId: objectId,
    page: 1,
    limit: 10,
  });

  const handleCreateJournal = () => {
    mutate(
      { buildingObjectId: objectId },
      {
        onSuccess: () => {
          notification.success({
            message: "Успешно",
            description: "Создали журнал",
            placement: "topRight",
            showProgress: false,
          });
        },
        onError: () => {
          notification.error({
            message: "Успешно",
            description: "Невозможно создать журнал",
            placement: "topRight",
            showProgress: false,
          });
        },
      },
    );
  };
  return (
    <>
      {/* {contextHolder} */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Title level={4}>Список журналов обьекта</Title>
        <Button type="default" onClick={handleCreateJournal}>
          Создать ОЖР
        </Button>
      </div>
      <ObjectJournalsList />
      {isPending ? <ObjectJournalsSkeleton /> : null}
    </>
  );
};
