import "./project-members.scss";
import { Can } from "@shared/lib/casl";
import { AddOrganizationWindow } from "../add-organization-window/add-organization-window.tsx";
import { ProjectMembersTable } from "../project-members-table/project-members-table.tsx";

export const ProjectMembers = () => {
  return (
    <div className="project-members-main-wrapper">
      <ProjectMembersTable />
      <Can I="edit" a="project">
        <AddOrganizationWindow />
      </Can>
    </div>
  );
};
