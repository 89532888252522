import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { TestPage } from "./ui";

export const TestPageRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `/test-page`,
    element: createElement(TestPage),
    children: [...rest],
  };
};
