export class ObjectQueriesTags {
  static root() {
    return ["object"] as const;
  }

  static getAllObjects() {
    return [...ObjectQueriesTags.root(), "allObjects"] as const;
  }

  static getObject(params: { objectId: string }) {
    return [...ObjectQueriesTags.root(), params.objectId] as const;
  }
}
