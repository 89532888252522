import { ReactNode, useEffect, useState } from "react";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import { env } from "@shared/config.ts";
import { useAuth } from "@shared/lib/hooks/use-auth.ts";

export const PrivateCheck = ({ children }: { children: ReactNode }) => {
  const { isProccesing, isAuth } = useAuth({ time: 3000 });
  const [timeTillRedirect, setTimeTillRedirect] = useState(3);
  useEffect(() => {
    if (!isProccesing && !isAuth) {
      setInterval(() => {
        setTimeTillRedirect((v) => (v >= 0 ? v - 1 : v));
      }, 1000);
    }
  }, [isProccesing, isAuth]);
  if (isProccesing && !isAuth) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!isProccesing && !isAuth) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <p>
          Ошибка авторизации. Вы будете перенаправлены на страницу авторизации
          через {timeTillRedirect}
        </p>
        <p>
          <Link
            to={`${env.OLD_FRONTEND_URL}/sign-in&redirectTo=${env.ACTUAL_FRONTEND}`}
          >
            Перейти вручную
          </Link>
        </p>
      </div>
    );
  }
  return <>{children}</>;
};
