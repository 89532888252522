import { useState } from "react";
import { Button, Drawer, Table, Typography } from "antd";
import { ProjectParticipantInfo } from "@entities/project";
import { fallbackString } from "@shared/utils";

const { Column } = Table;

export function MemberLink({
  company,
}: {
  company: ProjectParticipantInfo["company"];
}) {
  const [open, setOpen] = useState<boolean>(false);
  const onOpenHandler = () => {
    setOpen(true);
  };
  const onCloseHandler = () => {
    setOpen(false);
  };
  const dataSource = [
    {
      name: "ИНН",
      value: fallbackString(company.inn),
    },
    {
      name: "КПП",
      value: fallbackString(company.kpp),
    },
    {
      name: "ОГРН",
      value: fallbackString(company.ogrn),
    },
    {
      name: "Дата образования",
      value: fallbackString(""),
    },
    {
      name: "Юридический адрес",
      value: fallbackString(company.addresses.legal?.fullAddress),
    },
    {
      name: "Роль",
      value: fallbackString(""),
      // value: "NOT IMPLEMENTED",
    },
    {
      name: "Объект",
      value: fallbackString(""),
      // value: "NOT IMPLEMENTED",
    },
    {
      name: "Дата добавления",
      value: fallbackString(""),
      // value: company.updateDate,
    },
  ];

  return (
    <>
      <Button
        className="project-members-link"
        variant="link"
        color="primary"
        onClick={onOpenHandler}
      >
        {company.shortName}
      </Button>
      <Drawer
        onClose={onCloseHandler}
        open={open}
        size="large"
        title={company.shortName}
      >
        <Table dataSource={dataSource} pagination={false}>
          <Column
            dataIndex="name"
            render={(value) => (
              <Typography.Text type="secondary">{value}</Typography.Text>
            )}
            width="33%"
          />
          <Column
            dataIndex="value"
            render={(value) => <Typography.Text>{value}</Typography.Text>}
          />
        </Table>
      </Drawer>
    </>
  );
}
