import { Card, Space } from "antd";
import "./object-journals-skeleton.scss";

export const ObjectJournalsSkeleton = () => {
  return (
    <Card className="card-skeleton" style={{ padding: "12px" }}>
      <div className="row">
        <div className="placeholder title"></div>
        <div className="placeholder badge"></div>
        <div className="placeholder status"></div>
      </div>
      <div className="row">
        <div className="placeholder address"></div>
      </div>
      <div className="row">
        <div className="placeholder date"></div>
      </div>
    </Card>
  );
};

