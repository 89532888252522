import { Button, notification, Table, Typography } from "antd";
import "./object-info-opinion.scss";
import { format } from "date-fns";
import { s3AdapterApi } from "@shared/api";
import { DocumentatuionExaminations } from "@shared/api/object/types.ts";
import { useState } from "react";

const { Title } = Typography;

const downloadFile = async (
  fileId: string,
  setLoading: (fileId: string, loading: boolean) => void,
) => {
  setLoading(fileId, true);
  try {
    const response = await s3AdapterApi.get(`files/${fileId}?type=URL`);
    const fileUrl = response.data.content;

    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.error({
        message: "Ошибка при загрузке",
        description: "Ссылка для скачивания не найдена",
      });
    }
  } catch (error) {
    notification.error({
      message: "Ошибка при загрузке",
      description: "Ошибка при скачивании файла",
    });
  } finally {
    setLoading(fileId, false);
  }
};

export const ObjectInfoOpinion = ({
  data,
}: {
  data: DocumentatuionExaminations[];
}) => {
  const [loadingFileIds, setLoadingFileIds] = useState<Record<string, boolean>>(
    {},
  );
  const setLoading = (fileId: string, loading: boolean) => {
    setLoadingFileIds((prev) => ({ ...prev, [fileId]: loading }));
  };

  const columns = [
    {
      title: "№ заключения",
      dataIndex: "number",
      key: "number",
      render: (text: string, record: DocumentatuionExaminations) => {
        const fileId =
          record.examinationFile.file?.fileId ||
          record.examinationFile.signature?.fileId;

        if (fileId) {
          return (
            <Button
              type="link"
              loading={loadingFileIds[fileId]}
              onClick={() => downloadFile(fileId as string, setLoading)}
            >
              {text}
            </Button>
          );
        }
        return <>{text}</>;
      },
    },
    {
      title: "Дата выдачи",
      dataIndex: "date",
      key: "date",
      render: (text: string | null) => (
        <>{text ? format(new Date(text), "dd.MM.yyyy") : "-"}</>
      ),
    },
    {
      title: "Наименование органа, выдавшего заключение",
      dataIndex: "issued",
      key: "issued",
      render: (text: string) => (
        <div style={{ maxWidth: "490px", minWidth: "490px" }}>{text}</div>
      ),
    },
  ];

  return (
    <div className="table-container">
      <Title level={5}>
        Сведения о положительном заключении экспертизы проектной документации
      </Title>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        className="custom-table"
        scroll={{ x: true }}
      />
    </div>
  );
};
