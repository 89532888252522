import { Modal } from "antd";
import {
  RejectModalContent,
  CommentModalContent,
  WithdrawModalContent,
} from "./ui";
import { ReasonForSubmissionModalContent } from "./ui/reason-submission-modal-content";
import { ReturnToWorkModalContent } from "./ui/return-work-modal-content";

interface SideModalRejectProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  type: string;
}

export const SideModalReject = ({
  isVisible,
  onClose,
  title,
  type,
}: SideModalRejectProps) => {
  const renderContent = () => {
    switch (type) {
      case "reject":
        return <RejectModalContent />;
      case "comment":
        return <CommentModalContent />;
      case "withdraw":
        return <WithdrawModalContent />;
      case "return":
        return <ReturnToWorkModalContent />;
      case "reasonForSubmission":
        return <ReasonForSubmissionModalContent />;
      default:
        return null;
    }
  };

  return (
    <Modal visible={isVisible} title={title} onCancel={onClose} footer={null}>
      {renderContent()}
    </Modal>
  );
};

