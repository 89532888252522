import { Skeleton, Space, Card } from "antd";
import "./settings-skeleton-list.scss";

export const SettingsSkeletonList: React.FC = () => {
  return (
    <div className="settings-skeleton-list">
      <div className="settings-header">
        <Skeleton.Input active className="settings-title-skeleton" />
        <Skeleton.Button active className="settings-button-skeleton" />
      </div>
      <Space wrap size="large">
        {[1, 2, 3].map((key) => (
          <Card key={key} className="settings-card">
            <Skeleton active paragraph={{ rows: 2 }} title={false} />
          </Card>
        ))}
      </Space>
    </div>
  );
};

