import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ProjectQueriesTags } from "@shared/api/project";
import { getProjectParticipants } from "../api/get-participants";

interface IUseGetProjectParticipants {
  projectId: string;
  page?: number;
  limit?: number;
  search?: string;
}

export function useGetProjectParticipants({
  projectId,
  ...params
}: IUseGetProjectParticipants) {
  return useQuery({
    queryFn: () => getProjectParticipants({ projectId, ...params }),
    queryKey: ProjectQueriesTags.getProjectParticipants({
      projectId,
      ...params,
    }),
    placeholderData: keepPreviousData,
  });
}
