import { Card, Table, Typography } from "antd";

import "./selected-card.scss";
import { format } from "date-fns";
import { useEffect } from "react";
import { CertificateInfoInterface } from "@shared/lib/crypto-pro";

const { Column } = Table;

type SelectedCartProps = {
  person: CertificateInfoInterface;
};

const SelectedCard = ({ person }: SelectedCartProps) => {
  useEffect(() => {
    console.log(person);
  }, [person]);

  const dataSource = [
    {
      name: "ИНН",
      value: person.Subject.INN,
    },
    {
      name: "СНИЛС",
      value: person.Subject.SNILS,
    },
    {
      name: "Алгоритм",
      value: person.Algorithm,
    },
    {
      name: "Выдан",
      value: format(person.ValidFromDate, "dd-MM-yyyy"),
    },
    {
      name: "Владелец",
      value: person.Subject.CN,
    },
    {
      name: "Номер сертификата",
      value: person.SerialNumber,
    },
  ];

  return (
    <Card
      classNames={{ body: "cryptography-selected-person-card-body" }}
      style={{ marginTop: 16 }}
    >
      <Typography.Title level={5} style={{ marginBottom: 10 }}>
        {person.Name}
      </Typography.Title>
      <p
        style={{
          color: person.IsValid ? "#43A047" : "#F20404",
          border: "1px solid",
          borderColor: person.IsValid ? "#43A047" : "#F20404",
          padding: "5px 8px",
          display: "inline-block",
          borderRadius: 16,
        }}
      >
        {person.IsValid ? "Валидный" : "Не валидный"}
      </p>
      <Table
        dataSource={dataSource}
        className="cryptography-selected-person-table"
        pagination={false}
      >
        <Column
          dataIndex="name"
          render={(value) => (
            <Typography.Text type="secondary">{value}</Typography.Text>
          )}
          width="33%"
        />
        <Column
          dataIndex="value"
          render={(value) => <Typography.Text>{value}</Typography.Text>}
        />
      </Table>
    </Card>
  );
};

export default SelectedCard;
