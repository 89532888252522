import { Card, Space } from "antd";
import "./object-journals-list-skeleton.scss";
import { ObjectJournalsSkeleton } from "../object-journals-skeleton";

export const ObjectJournalsListSkeleton = () => {
  const skeletons = [1, 2, 3];

  return (
    <Space
      direction="vertical"
      size="large"
      className="object-journals-list-skeleton"
    >
      {skeletons.map((item) => (
        <ObjectJournalsSkeleton key={item} />
      ))}
    </Space>
  );
};

