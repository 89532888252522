import { memo } from "react";
import { Select, SelectProps } from "antd";

const PartySelect = memo(
  ({
    itemCount,
    ...rest
  }: {
    itemCount: number;
  } & SelectProps) => (
    <Select
      listHeight={60 * itemCount}
      listItemHeight={60}
      notFoundContent={null}
      filterOption={false}
      showSearch
      optionLabelProp="inputLabel"
      popupClassName="party-suggestions-popup"
      placeholder="Введите название или ИНН"
      virtual={false}
      {...rest}
    />
  ),
);

export default PartySelect;
