import {
  Form,
  Select,
  Button,
  DatePicker,
  Input,
  Typography,
  Avatar,
  Space,
  Table,
} from "antd";
import "./journal-section-form.scss";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { RepresentationMoodal } from "@/features/representation-modal";
import { useDisclosure } from "@/shared/lib/hooks";
import { useState } from "react";

const { Text } = Typography;

const { Option } = Select;

interface IJournalSectionForm {
  handleIsForm: (
    status: boolean,
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  title?: string;
}

const data = [
  {
    key: "test",
    name: "test",
    organization: "test",
    position: "test",
    initials: "test",
    email: "test",
    phone: "test",
    role: "test",
    nostroyId: "test",
  },
];

export const JournalSectionForm = ({
  handleIsForm,
  title,
}: IJournalSectionForm) => {
  const { handleSubmit, control } = useForm();
  const [userSelected, setUserSelected] = useState<string | null>(null);

  const onSubmit = (data: any) => {
    console.log("Submitted Data:", data);
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleClose = () => {
    onClose();
  };
  const handleAdd = (id?: string) => {
    setUserSelected("test id ");
    console.log(id);
    onClose();
  };

  const handleIsHide = () => {
    setUserSelected(null);
  };

  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => (
        <Space size="middle">
          <Avatar>{record.initials}</Avatar>
          <Text>{name}</Text>
        </Space>
      ),
    },
    {
      title: "Организация",
      dataIndex: "organization",
      key: "organization",
      render: (organization: string) => <Text>{organization}</Text>,
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
      render: (position: string) => <Text>{position}</Text>,
    },
    {
      key: "action",
      render: () => (
        <Button
          type="text"
          icon={
            <CloseCircleOutlined style={{ color: "red", fontSize: "16px" }} />
          }
          danger
          style={{ padding: 0 }}
          onClick={handleIsHide}
        />
      ),
    },
  ];
  return (
    <div className="journal-object-wrapper">
      <DevTool control={control} />
      <div className="journal-object-container">
        <div style={{ marginBottom: "24px", width: "100%", maxWidth: 720 }}>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            {title}
          </Typography.Title>
        </div>

        <Form
          onFinish={handleSubmit(onSubmit)}
          layout="vertical"
          className="custom-form"
        >
          <Form.Item
            style={{ marginBottom: "24px" }}
            label="Организация, осуществляющая строительство"
            name="organization"
            rules={[
              { required: true, message: "Пожалуйста, выберите организацию" },
            ]}
          >
            <Select placeholder="Выберите организацию">
              <Option value="genpodryadchik">ООО "Генподрядчик"</Option>
              <Option value="subpodryadchik">ООО "Субподрядчик"</Option>
            </Select>
          </Form.Item>
          {userSelected ? (
            <div className="rep-author-modal">
              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                showHeader
                bordered={false}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}

          <Form.Item style={{ marginBottom: "24px" }}>
            <Button type="primary" onClick={onOpen}>
              Добавить сотрудника
            </Button>
          </Form.Item>

          <div className="form-dates">
            <Form.Item
              label="Дата начала работ"
              name="startDate"
              rules={[
                { required: true, message: "Пожалуйста, выберите дату начала" },
              ]}
              style={{ marginBottom: "24px" }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Дата окончания работ (необ.)"
              name="endDate"
              style={{ marginBottom: "24px" }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <Form.Item
            style={{ marginBottom: "24px" }}
            label="Вид работ"
            name="workType"
            rules={[
              { required: true, message: "Пожалуйста, укажите вид работ" },
            ]}
          >
            <Input placeholder="Укажите вид работ" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "24px" }}
            label="Уполномоченный представитель лица"
            name="representative"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите представителя",
              },
            ]}
          >
            <Select placeholder="Выберите представителя">
              <Option value="zastroev">
                Застроев Г. А., Начальник участка
              </Option>
              <Option value="ivanov">Иванов О. П., Директор</Option>
            </Select>
          </Form.Item>

          <Form.Item style={{ marginBottom: "24px" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!userSelected}
              style={{ height: "44px" }}
            >
              Добавить запись
            </Button>
            <Button style={{ marginLeft: 8, height: 44 }}>Отмена</Button>
          </Form.Item>
        </Form>
        <CloseOutlined
          className="object-form-icon-close"
          onClick={(event) => handleIsForm(false, event)}
        />
      </div>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={handleClose}
        handleAdd={handleAdd}
      />
    </div>
  );
};

