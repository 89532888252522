import { CloseWhiteIcon, SuccessIcon } from "@/shared/assets/svg/icons";
import "./journals-sige-info.scss";
import { signStatusEnum } from "@/app/composition/views/project-journals/ui/project-journals";
import { Button } from "antd";
import { useState } from "react";
import { SignatureModal } from "./ui/signature-modal/signature-modal";

interface IJournalsSigeInfo {
  signStatus: signStatusEnum;
  setSignStatus: React.Dispatch<React.SetStateAction<signStatusEnum>>;
}

export const JournalsSigeInfo = ({
  signStatus
}: IJournalsSigeInfo) => {
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      {signStatus === signStatusEnum.signed ||
      signStatus === signStatusEnum.rejected ? (
        <div
          className={`represented-container ${signStatus === signStatusEnum.signed ? "success" : ""}${signStatus === signStatusEnum.rejected ? "rejected" : ""}`}
        >
          <div
            className="flex-between"
            style={{ fontSize: 16, marginBottom: "16px" }}
          >
            <div className="flex-default">
              <div
                className={`represented-icon-container ${signStatus === signStatusEnum.signed ? "success" : ""}${signStatus === signStatusEnum.rejected ? "rejected" : ""}`}
              >
                {signStatus === signStatusEnum.signed ? <SuccessIcon /> : null}
                {signStatus === signStatusEnum.rejected ? (
                  <CloseWhiteIcon />
                ) : null}
              </div>
              {signStatus === signStatusEnum.signed
                ? signStatusEnum.signed
                : ""}
              {signStatus === signStatusEnum.rejected
                ? signStatusEnum.rejected
                : ""}
            </div>
            <div>11.07.2024 13:10</div>
          </div>
          {signStatus === signStatusEnum.signed ? (
            <>
              <div style={{ fontSize: 12, marginBottom: 8 }}>
                Доверенность № 123-32b-1w1e-77er
              </div>
              <div style={{ fontSize: 12 }}>с 11.07.2024 по 11.07.2025</div>
            </>
          ) : null}
          {signStatus === signStatusEnum.rejected ? (
            <>
              <div style={{ fontSize: 12, marginBottom: 8 }}>
                Я Уполномоченный со стороны застройщика не подписывался!
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {signStatus === signStatusEnum.onSign ? (
        <div className="represented-container-on-sign">
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Подписать
          </Button>
          <Button type="dashed">Отказаться</Button>
        </div>
      ) : null}
      <SignatureModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};

