import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Layout, Spin } from "antd";
import { Outlet } from "react-router-dom";
import { projectQueries } from "@entities/project";
import { ProjectAbilityBuilder, useUpdateAbility } from "@shared/lib/casl";
import { useCoordinator } from "@shared/lib/hooks";
import { NotFoundFallback } from "@shared/ui";
import Navigation from "./navigation";

const ProjectWrapper = () => {
  const [isDev, setIsDev] = useState(false);
  const { projectId } = useCoordinator() as {
    projectId: string;
  };

  const {
    data: project = null,
    isLoading,
    isError,
  } = useQuery(projectQueries.getProject({ projectId }));

  useUpdateAbility({ entity: project, abilityBuilder: ProjectAbilityBuilder });

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 104px)",
          minWidth: "99vw",
        }}
      >
        <Spin size="large" tip="Загрузка проекта">
          <div style={{ padding: 4, width: 200 }} />
        </Spin>
      </div>
    );
  if (isError && !isDev) {
    return (
      <NotFoundFallback
        setIsDev={setIsDev}
        style={{
          width: "100%",
          height: "calc(100vh-104px)",
          justifyContent: "center",
          display: "flex",
        }}
      />
    );
  }

  return (
    <Layout>
      {isDev ? null : <Navigation />}
      <Outlet />
    </Layout>
  );
};

export default ProjectWrapper;
