import { Flex, Input, Typography } from "antd";
import { FormItem } from "react-hook-form-antd";

const CustomerBlock = ({
  methods,
  representative,
}: {
  methods: any;
  representative: "customer" | "developer";
}) => {
  return (
    <Flex style={{ marginBottom: 0 }} vertical gap={8}>
      <Typography.Title level={5}>
        {representative === "customer"
          ? "Информация о ген. подрядчике"
          : "Информация о заказчике"}
      </Typography.Title>
      <FormItem
        style={{ marginBottom: 0 }}
        control={methods.control}
        name={`${representative}.name`}
      >
        <Input placeholder="Наименование организации" />
      </FormItem>
      <FormItem
        style={{ marginBottom: 0 }}
        control={methods.control}
        name={`${representative}.address`}
      >
        <Input placeholder="Почтовые реквизиты" />
      </FormItem>

      <Flex gap={8}>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.inn`}
        >
          <Input placeholder="Инн" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.ogrn`}
        >
          <Input placeholder="Огрн" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.sro`}
        >
          <Input placeholder="СРО" />
        </FormItem>
      </Flex>

      <Flex gap={8}>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.kpp`}
        >
          <Input placeholder="КПП" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.ogrnDate`}
        >
          <Input placeholder="Дата выдачи ОГРН" />
        </FormItem>
        <FormItem
          style={{ marginBottom: 0 }}
          control={methods.control}
          name={`${representative}.sroDate`}
        >
          <Input placeholder="Дата СРО" />
        </FormItem>
      </Flex>
      <FormItem control={methods.control} name={`${representative}.phone`}>
        <Input placeholder="Телефон" />
      </FormItem>
    </Flex>
  );
};

export default CustomerBlock;
