import { Input, Button } from "antd";
import "./comment-modal-content.scss";

export const CommentModalContent = () => {
  return (
    <>
      <Input.TextArea placeholder="Введите текст замечания" rows={4} />
      <Button type="primary" style={{ marginTop: 16 }}>
        Отправить
      </Button>
    </>
  );
};

