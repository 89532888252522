import { Button, Flex, Typography } from "antd";
import {
  CryptographyNotification,
  CryptographyNotificationTypeList,
} from "../cryptography-notification";

const EmptyComponent = () => {
  return (
    <Flex vertical gap={16}>
      <CryptographyNotification
        notificationType={
          CryptographyNotificationTypeList.StorageNotInitialized
        }
      />
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        Убедитесь в следующем:
        <br />
        1. Рабочее место настроено (
        <Button type="link" style={{ padding: 0 }}>
          Инструкция
        </Button>
        )
        <br />
        2. Сертификат установлен на компьютер
      </Typography.Paragraph>
      <Typography.Paragraph style={{ color: "#888888", marginBottom: 0 }}>
        Разрешите браузеру операции с сертификатами
      </Typography.Paragraph>
    </Flex>
  );
};

export default EmptyComponent;
