import { Button, Checkbox, Dropdown, Menu } from "antd";
import "./input-select.scss";
import { CloseIcon, PlusIconBlack } from "@/shared/assets/svg/icons";
import { ReactNode } from "react";

type IStatus = {
  status: string;
  icon: ReactNode;
  id: string;
};

interface IInputSelect {
  title: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  type?: "select" | "date";
  selectedStatuses?: IStatus[];
  setSelectedStatuses?: React.Dispatch<React.SetStateAction<IStatus[]>>;
  statuses?: IStatus[];
}

export const InputSelect = ({
  title,
  onClick,
  style,
  type = "select",
  selectedStatuses = [],
  setSelectedStatuses,
  statuses = [],
}: IInputSelect) => {
  const handleCheckboxChange = (status: IStatus, checked: boolean) => {
    if (setSelectedStatuses) {
      if (checked) {
        setSelectedStatuses([...selectedStatuses, status]);
      } else {
        setSelectedStatuses(selectedStatuses.filter((s) => s.id !== status.id));
      }
    }
  };

  const handleRemoveStatus = (id: string) => {
    if (setSelectedStatuses) {
      setSelectedStatuses(selectedStatuses.filter((s) => s.id !== id));
    }
  };

  const menu = (
    <Menu>
      {statuses.map((status) => (
        <Menu.Item
          key={status.id}
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Checkbox
            checked={selectedStatuses.some((s) => s.id === status.id)}
            onChange={(e) => handleCheckboxChange(status, e.target.checked)}
          >
            {status.icon} {status.status}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return type === "select" ? (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        className={`input-select-container ${
          selectedStatuses.length > 0 ? "selected" : ""
        }`}
        onClick={onClick}
        style={style}
      >
        <div className="input-select-title">
          {selectedStatuses.length > 0 ? "Статус" : title}
        </div>
        {selectedStatuses.length > 0 ? (
          <div className="status-list">
            {selectedStatuses.map((item) => (
              <div key={item.id} className="status-item">
                <div>{item.status}</div>
                <CloseIcon
                  className="status-remove"
                  onClick={(e) => {
                    handleRemoveStatus(item.id);
                    e.stopPropagation();
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <PlusIconBlack className="input-select-icon" />
        )}
      </Button>
    </Dropdown>
  ) : (
    <Button className="input-select-container" onClick={onClick} style={style}>
      <div className="input-select-title">{title}</div>
      <PlusIconBlack className="input-select-icon" />
    </Button>
  );
};

export type { IStatus };

