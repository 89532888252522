import { JSX } from "react";
import InfoIcon from "./icons/info-icon.svg?react";
import WarningInfoIcon from "./icons/warning-info-icon.svg?react";

import "./cryptography-notification.scss";

export enum CryptographyNotificationTypeList {
  StorageNotInitialized = "storage not initialized",
  InvalidCertificate = "invalid certificate",
}

export type CryptographyNotificationProps = {
  notificationType: CryptographyNotificationTypeList;
};

export const CryptographyNotification = ({
  notificationType,
}: CryptographyNotificationProps) => {
  const messages: Record<CryptographyNotificationTypeList, string> = {
    [CryptographyNotificationTypeList.StorageNotInitialized]:
      "Не удалось найти сертификаты ЭЦП",
    [CryptographyNotificationTypeList.InvalidCertificate]:
      "Подпись можно сделать только валидным ключом",
  };
  const icons: Record<CryptographyNotificationTypeList, JSX.Element> = {
    [CryptographyNotificationTypeList.StorageNotInitialized]: (
      <WarningInfoIcon className="cryptography-notification__icon" />
    ),
    [CryptographyNotificationTypeList.InvalidCertificate]: (
      <InfoIcon className="cryptography-notification__icon" />
    ),
  };

  return (
    <div className="cryptography-notification">
      {icons[notificationType]}
      <span className="cryptography-notification__text">
        {messages[notificationType]}
      </span>
    </div>
  );
};
