import type { DaDataPartySuggestion } from "../types";

export interface GetDaDataSuggestRequest {
  query: string;
  count?: number;
}

export interface GetDaDataSuggestResponse {
  suggestions: Array<DaDataPartySuggestion>;
}

export class DaDataService {
  static readonly #token = import.meta.env.VITE_DADATA_API_KEY as string;

  static readonly #url = import.meta.env.VITE_DADATA_API_URL as string;

  static async getDaDataSuggestParty(
    request: GetDaDataSuggestRequest,
  ): Promise<GetDaDataSuggestResponse> {
    const options: RequestInit = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${this.#token}`,
      },
      body: JSON.stringify(request),
    };
    const response = await fetch(`${this.#url}/party`, options);
    return response.json();
  }
}
