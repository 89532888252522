import "./return-work-modal-content.scss";

import { Button, Divider } from "antd";

export const ReturnToWorkModalContent = () => {
  return (
    <>
      <div>Вы действительно хотите вернуть в работу титульный лист?</div>
      <Divider />
      <div style={{ display: "flex", gap: "8px", marginTop: 16 }}>
        <Button type="primary">Вернуть</Button>
        <Button>Отменить</Button>
      </div>
    </>
  );
};

