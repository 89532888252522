import { Input, Button } from "antd";

import "./reject-modal-content.scss";

export const RejectModalContent = () => {
  return (
    <>
      <Input.TextArea
        placeholder="Опишите причину решения. Ошибка, например"
        rows={4}
      />
      <Button type="primary" style={{ marginTop: 16 }}>
        Отклонить
      </Button>
    </>
  );
};

