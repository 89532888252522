import { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import "./representation-form-email.scss";
import { User } from "@entities/user/types/user";
import { useMutation } from "@tanstack/react-query";
import { findUser } from "@entities/user";

interface IRepresentationFormEmail {
  setIsShowFormAdd: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (user: User) => void;
  error?: string;
}

export const RepresentationFormEmail = ({
  setIsShowFormAdd,
  onSuccess,
  error,
}: IRepresentationFormEmail) => {
  const [emailValue, setEmailValue] = useState("");
  const { mutateAsync, isPending } = useMutation({ mutationFn: findUser });
  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  // TODO: использовать или убрать
  // eslint-disable-next-line no-void
  void setIsShowFormAdd;

  const handleAddRepresentation = () => {
    mutateAsync({ email: emailValue })
      .then((result) => {
        if (!result) {
          notification.error({
            message: `Сотрудник не найден`,
            description: "Попробуйте другие данные",
            placement: "topRight",
            showProgress: false,
          });
        } else {
          console.log("server responce", result.data);
          onSuccess(result.data);
        }
      })
      .catch((e) => {
        notification.error({
          message: `${e?.response?.data?.error}  ${e?.response?.data?.code}`,
          description: `${e?.response?.data?.errorDescription}`,
          placement: "topRight",
          showProgress: false,
        });
      });
  };
  useEffect(() => {
    if (error) {
      notification.error({
        message: `Неудачный запрос`,
        description: error,
        placement: "topRight",
        showProgress: false,
      });
    }
  }, [error]);
  return (
    <div className="email-input-container">
      {/* {contextHolder} */}
      <div className="email-inpit-lable">
        <div className="email-input-title">Электронная почта</div>
        <Input
          placeholder="почта"
          style={{ minWidth: "320px" }}
          value={emailValue}
          onChange={handleEmail}
        />
      </div>
      <Button
        type="primary"
        loading={isPending}
        disabled={emailValue.length < 11}
        onClick={handleAddRepresentation}
      >
        Найти
      </Button>
    </div>
  );
};
