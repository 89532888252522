import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { Link } from "react-router-dom";
import "./representation-form-add.scss";
import { DocumentSelector } from "@/shared/ui/document-selector";

export const RepresentationFormAdd = () => {
  const [loadDocuments, setLoadDocuments] = useState<{
    [key: string]: { title: string; status: boolean; id: number };
  }>({
    firstDocument: {
      title: "Прикрепить документ",
      status: false,
      id: 1,
    },
    secondElement: {
      title: "Прикрепить подпись",
      status: false,
      id: 2,
    },
  });

  const toggleDocumentStatus = (key: string) => {
    setLoadDocuments((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        status: !prev[key].status,
      },
    }));
  };

  return (
    <>
      <div className="confirmation-credentials">
        <div className="confirmation-credentials-inputs-container">
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">
              Наименование документа
            </div>
            <Input placeholder="Приказ" />
          </div>
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">
              Номер документа
            </div>
            <Input placeholder="№ГГ-12" />
          </div>
          <div className="confirmation-credentials-label">
            <div className="confirmation-credentials-title">Дата выдачи </div>
            <Input placeholder="12.12.2012" type="date" />
          </div>
        </div>
      </div>
      <DocumentSelector
        loadDocuments={loadDocuments}
        toggleDocumentStatus={toggleDocumentStatus}
      />
      <div className="organization-credentials">
        <div className="organization-credentials-label">
          <div className="organization-credentials-title">
            Организация, выдавшая документ (необ.)
          </div>
          <Input placeholder="" />
        </div>
        <div className="organization-credentials-label">
          <div className="organization-credentials-title">Описание (необ.)</div>
          <Input placeholder="" />
        </div>
      </div>
    </>
  );
};
