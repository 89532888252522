import { useQuery } from "@tanstack/react-query";
import { Card, Button, Row, Col, Avatar, Table, Space, Typography } from "antd";
import { projectQueries } from "@entities/project";
import { useAddProjectAdmin } from "@entities/project/queries/use-project-admins.ts";
import { RepresentationMoodal } from "@features/representation-modal";
import { Can } from "@shared/lib/casl";
import { useCoordinator, useDisclosure } from "@shared/lib/hooks";
import "./project-detail.scss";
import { ProjectAvatar } from "../index.ts";

const columns = [
  {
    title: "ФИО",
    dataIndex: "fullName",
    key: "fullName",
    render: (text: string, record: any) => (
      <Space>
        <Avatar src={record.avatar} style={{ width: "30px", height: "30px" }} />
        <Typography.Text>{text}</Typography.Text>
      </Space>
    ),
  },
  {
    title: "Организация",
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: "Должность",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
];

export default function ProjectDetail() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { projectId } = useCoordinator() as { projectId: string };

  const { data: admins, isLoading } = useQuery(
    projectQueries.getProjectAdmin({ projectId }),
  );

  const { data: project } = useQuery(projectQueries.getProject({ projectId }));

  const { mutateAsync, isPending, error } = useAddProjectAdmin(projectId || "");

  const handleAddAdmin = (id?: string) => {
    if (id) {
      mutateAsync(id).then();
    }
  };

  const onCopyIdClick = () => {
    navigator.clipboard.writeText(project?.projectId ?? "");
  };

  const transformedData =
    admins?.userInfo?.map((admin) => ({
      key: admin.userId,
      // avatar: admin.userAvatarId
      //   ? `https://your-image-service/${admin.userAvatarId}`
      //   : `https://xsgames.co/randomusers/avatar.php?g=male`,
      avatar: "https://xsgames.co/randomusers/avatar.php?g=male",
      fullName: `${admin.userSurname} ${admin.userFirstName} ${admin.userMiddleName ?? ""}`,
      organization: admin.userCompanies?.[0]?.shortName || "Не указано",
      position: admin.userCompanies?.[0]?.position || "Не указано",
      email: admin.userEmail,
      phone: admin.userMobilePhone,
    })) || [];

  return (
    <>
      <Card style={{ marginTop: 16 }}>
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Col
            xs={24}
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ProjectAvatar projectId={projectId} />
          </Col>
          <Col xs={24} lg={18} style={{ paddingLeft: "16px" }}>
            <div className="project-info-container">
              <div className="project-title-container">
                <div className="project-title">{project?.shortName}</div>
              </div>
              <div className="project-description">{project?.fullName}</div>
              <div className="description">
                <div className="description-item">
                  <span className="label">Адрес</span>
                  <span className="value">{project?.addressUnstructured}</span>
                </div>
                <div className="description-item">
                  <span className="label">ID проекта</span>
                  <span className="value">
                    <div>{project?.projectId}</div>
                    <Button type="link" onClick={onCopyIdClick}>
                      Скопировать
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="table-container" style={{ marginTop: "16px" }}>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Администраторы проекта
          </Typography.Title>
          <Can I="access" a="project">
            <Button type="link" onClick={onOpen}>
              Добавить
            </Button>
          </Can>
        </Space>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={transformedData}
          pagination={false}
          bordered={false}
        />
      </div>
      <RepresentationMoodal
        isRepresentation={isOpen}
        handleClose={onClose}
        handleAdd={handleAddAdmin}
        title="Добавить администратора"
        type="settings"
        isLoading={isPending}
        // @ts-ignore
        error={error?.response?.data?.errorDescription}
      />
    </>
  );
}
