import { useState } from "react";
import { Avatar, Button, Divider, Modal, Space, Table, Typography } from "antd";
import "./representation-modal.scss";
import { RepresentationFormAdd } from "../representation-form-add";
import { RepresentationFormEmail } from "../representation-form-email";
import { CloseCircleOutlined } from "@ant-design/icons";
// import { BasketIcon } from "@/shared/assets/svg/icons";
import { RepresentationFormAuthor } from "../representation-form-author";
import { User } from "@entities/user/types/user";
const { Text } = Typography;

interface IRepresentationMoodal {
  isRepresentation: boolean;
  handleClose: () => void;
  handleAdd: (id?: string) => void;
  title?: string;
  type?: "settings" | "author";
  isLoading?: boolean;
  error?: string;
}

export const RepresentationMoodal = ({
  isRepresentation,
  handleClose,
  handleAdd,
  title = "Добавление Уполномоченного",
  type,
  isLoading,
  error,
}: IRepresentationMoodal) => {
  const [isShowFormAdd, setIsShowFormAdd] = useState(false);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const handleIsShowFalse = () => {
    setIsShowFormAdd(false);
    setUserInfo(null);
  };
  const handleOnCancel = () => {
    handleClose();
  };
  const handleLocalAdd = () => {
    handleAdd(userInfo?.userId);
  };

  const onSuccess = (user: User) => {
    setUserInfo(user);
    setIsShowFormAdd(true);
  };

  const data = userInfo
    ? [
        {
          key: userInfo.userId,
          name: `${userInfo.surname} ${userInfo.firstName} ${userInfo.middleName}`,
          organization:
            userInfo.employment?.position?.description || "Нет данных",
          position: userInfo.employment?.position?.value || "Нет данных",
          initials: `${userInfo.surname[0] || ""}${userInfo.firstName[0] || ""}`,
          email: userInfo.email || "Нет данных",
          phone: userInfo.surname || "Нет данных",
          role: userInfo?.surname || "Нет данных",
          nostroyId: userInfo.surname || "Нет данных",
        },
      ]
    : [];

  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => (
        <Space size="middle">
          <Avatar>{record.initials}</Avatar>
          <Text>{name}</Text>
        </Space>
      ),
    },
    {
      title: "Организация",
      dataIndex: "organization",
      key: "organization",
      render: (organization: string) => <Text>{organization}</Text>,
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
      render: (position: string) => <Text>{position}</Text>,
    },
    {
      key: "action",
      render: () => (
        <Button
          type="text"
          icon={
            <CloseCircleOutlined style={{ color: "red", fontSize: "16px" }} />
          }
          danger
          style={{ padding: 0 }}
          onClick={handleIsShowFalse}
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        open={isRepresentation}
        onCancel={handleOnCancel}
        footer={null}
        centered
        width={700}
        title={title}
      >
        <div style={{ marginBottom: "24px", color: "#333333" }}>
          Поиск сотрудника по адресу электронной почты
        </div>

        <RepresentationFormEmail
          setIsShowFormAdd={setIsShowFormAdd}
          onSuccess={onSuccess}
          error={error}
        />

        {isShowFormAdd ? (
          <div className="rep-author-modal">
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              showHeader
              bordered={false}
              style={{ width: "100%" }}
            />
          </div>
        ) : null}

        {isShowFormAdd && type !== "settings" ? (
          <RepresentationFormAdd />
        ) : null}

        {type === "author" ? <RepresentationFormAuthor /> : null}

        <Divider />
        <Button
          type="primary"
          disabled={!isShowFormAdd}
          loading={isLoading}
          onClick={handleLocalAdd}
        >
          Добавить
        </Button>
      </Modal>
    </>
  );
};
