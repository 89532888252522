import { AbilityBuilder, PureAbility } from "@casl/ability";
import { Project } from "@entities/project";
import {
  Actions,
  SubjectRule,
  Subjects,
  PermissionsMap,
} from "@shared/lib/casl/types";

export function ProjectAbilityBuilder(project: Project): SubjectRule[] {
  const subject = "project" as Subjects;
  const actions = [] as Actions[];

  project?.permissions?.forEach((permission) => {
    if (permission.action === "ADMIN" || permission.action === "ALL_USER") {
      actions.push(...PermissionsMap[permission.action]);
    } else {
      const perm = permission.action as keyof typeof PermissionsMap;
      actions.push(...PermissionsMap[perm]);
    }
  });

  const { can, rules } = new AbilityBuilder<PureAbility<[Actions, Subjects]>>(
    PureAbility,
  );

  actions?.forEach((action) => {
    can(action, subject);
  });

  return rules;
}
