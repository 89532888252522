import { useContext, useEffect } from "react";
import { AbilityContext } from "@shared/lib/casl/ability-context";
import { Entity, SubjectRule } from "@shared/lib/casl/types";

export function useUpdateAbility({
  entity,
  abilityBuilder,
}: {
  entity: Entity | null;
  abilityBuilder: (arg0: Entity) => SubjectRule[];
}) {
  const ability = useContext(AbilityContext);
  let updatedAbility: SubjectRule[] | null = null;
  if (entity) {
    updatedAbility = abilityBuilder(entity);
  }
  useEffect(() => {
    if (updatedAbility) {
      ability.update(updatedAbility);
    }
  }, [updatedAbility, ability]);
}
